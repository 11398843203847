import { Helmet } from "react-helmet";

import bo from "images/slider_2.jpeg";

import locateIcon from "images/locate.png";
import phoneIcon from "images/phone.png";
import emailIcon from "images/email.png";
import facebookIcon from "images/facebook.png";
import instagramIcon from "images/instagram.png";
import { Link } from "react-router-dom";

const Contact = () => {

    return (
        <div className="nc-PageHome relative overflow-hidden">
            <Helmet>
                <title>Contact - fruitiers-anciens.fr</title>
            </Helmet>

            <div className="bg-[#e7d9c7] py-2">
                <div className="flex flex-row container lg:px-16 xl:px-16 md:px-16">
                    <Link to="/" className="underline cursor-pointer font-[500]">Accueil</Link>
                    <span className="ml-2 mr-2">{">"}</span>
                    <h1>Nous contacter</h1>
                </div>
            </div>


            <div className="bg-[white]">
                <div className={`container`}>
                    <div className={`flow-root`}>
                        <div className="flex text-3xl lg:px-36 justify-center flex-col py-8">
                            <div className="flex w-full">
                                <img className="flex w-full" src={bo} />
                            </div>

                            <div className="">
                                <div className="bg-[#f9f5ef] px-4 py-4">
                                    <h1 className="text-[32px] text-[#7c7d7d] leading-[45px]">Contacter le service consommateurs</h1>
                                    <div className="mt-6 mb-6">
                                        <p className="text-[#7c7d7d] text-[14px] leading-[20px]">Nous sommes à votre écoute pour toutes vos questions ou pour vos besoins. N'hésitez pas à nous faire part de vos envies, nous saurons vous accompagner afin que vos envies prennent vie !</p>
                                    </div>

                                    <div className="text-[14px] text—[#555]">
                                        <div className="flex flex-row">
                                            <img className="h-4 w-4 relative top-[.6rem] mr-1" src={locateIcon} />
                                            <h1 className="ml-1">570 Chemin du Moulin 82 370 CORBARIEU</h1>
                                        </div>
                                        <div className="flex flex-row">
                                            <img className="h-4 w-4 relative top-[.6rem] mr-1" src={phoneIcon} />
                                            <h1 className="ml-1">05 63 67 88 26 (service consommateurs)</h1>
                                        </div>
                                        <div className="flex flex-row">
                                            <img className="h-4 w-4 relative top-[.6rem] mr-1" src={emailIcon} />
                                            <h1 className="ml-1">saint-romas@orange.fr</h1>
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Contact;
