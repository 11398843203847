import React from "react";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import mastercardImg from "images/mastercard.svg";
import visaImg from "images/visa.svg";
import stripeImg from "images/stripe.svg";
import { COLORS } from "config/theme";
import { useHistory } from "react-router-dom";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "1",
    title: "Fruitiers Anciens",
    menus: [
      { href: "#", label: "570 Chemin du Moulin 82 370 CORBARIEU" },
      { href: "#", label: "Service consommateur: 05 63 67 88 26" },
      { href: "mailto:saint-romas@orange.fr", label: "saint-romas@orange.fr" },
    ],
  },
  {
    id: "2",
    title: "Nos catégories",
    menus: [
      { href: "/products/", label: "Abricotiers", id: '64b656dd56f7fddaae6da2db' },
      { href: "/products/", label: "Cerisiers", id: '64b656fb56f7fddaae6da2dd' },
      { href: "/products/", label: "Cognassiers", id: '64b6570d56f7fddaae6da2de' },
      { href: "/products/", label: "Néfliers", id: '64b6572356f7fddaae6da2e0' },
    ],
  },
  {
    id: "3",
    title: "Légal / Contact",
    menus: [
      { href: "/cgv", label: "CGV" },
      { href: "/mentions-legales", label: "Mentions légales" },
      { href: "/contact", label: "Nous contacter" },
    ],
  },
];

const Footer: React.FC = () => {
  const history = useHistory();

  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {


    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold xs:mb-3 xs:mt-6 lg:mb-5 font-[700] dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="">
          {menu.menus.map((item, index) => (
            <li key={index} className="xs:mt-2 lg:mt-1">
              <a
                key={index}
                className={`font-[400] dark:text-neutral-300 ${item?.label === "commercial@bieresdere-boutique.fr" && "underline"}`}
                onClick={() => { menu?.id === "2" && history.push({ pathname: item?.href, search: `?categories=${item?.label}` }) }}
                rel="noopener noreferrer"
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <>

      <div className={`nc-Footer relative py-10 lg:pb-24`} style={{ backgroundColor: COLORS.footer, color: COLORS?.footerText }}>


        <div className="container flex lg:flex-row xs:flex-col justify-between">
          {widgetMenus.map(renderWidgetMenuItem)}

          {/* <div className="">
            <h2 className="font-semibold xs:mb-3 xs:mt-6 lg:mb-5 font-[700] dark:text-neutral-200">
              Suivez-nous
            </h2>
            <div className="col-span-2 flex items-center md:col-span-3">
              <SocialsList1 className="flex items-center lg:flex-col lg:space-y-3 xs:space-x-1 lg:items-start" />
            </div>
          </div> */}
        </div>

        <div className="container mt-[35px]">

          <div className="border-t border-[#BFC6DB] ">
            <div className="mt-[25px] flex grid lg:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 grid-cols-1 justify-between">

              <div>
                <h1 className="text-[13px] mt-[3px]">
                  Services E-commerce développé par <a onClick={() => window.open('https://milango.io/')} className="underline hover:text-[#f90]" href="https://milango.io/">Milango</a>
                </h1>
              </div>

              <div className="flex flex-row lg:justify-end md:justify-end xl:justify-end">
                <h1 className="text-[13px] mt-[4px]">
                  Paiement sécurisé :
                </h1>

                <img width={60} className="ml-[8px]" src={stripeImg} />
                <img width={45} className="ml-[4px]" src={mastercardImg} />
                <img width={45} className="ml-[5px]" src={visaImg} />
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
