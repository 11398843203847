export const COLORS = {


    preHeader: "#F7ECDB",
    preHeaderText: "#234336",

    // NAVBAR 
    navbar: 'white',
    navbarText: '#74a534',
    navbarTextHover: '#1e6132',
    navbarArrow: '#74a534',


    // SLIDER
    sliderText: 'red',
    sliderBg: 'white',
    sliderBgText: "white",

    // FOOTER
    footer: '#74a534',
    footerText: '#FFFFFF',


    // BUTTON
    buttonPrimary: '#74a534',
    buttonSecondary: '#74a534',
    buttonThird: '#74a534',
    buttonFour: 'white',
    filterBtn: '#6E5D4A',

    // INPUT
    searchInputBorder: 'white',

    heading: '#234336',
    text: '#234336',
    headingCaroussel: "#74a534",
    paragraphCaroussel: "#234336",
    placeholderSearchInput: 'white',
    backgroundHowItWork: '#e7d9c7',
    loadingSpinner: '',
}

export const BORDERS = {
    buttonPrimary: '0px',
    buttonSecondary: '0px',
    buttonVariant: '30px',
    searchInput: '999px',
    inputQuantityNumber: '0px',
    buttonThird: '3px',
    buttonFour: '3px',
    input: ''
}
