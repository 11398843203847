
import { getAllProducts } from "actions/products";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import Img1 from '../../images/plateforme.jpeg'
import Img2 from '../../images/demi-tige-633x1024.png'
import Img3 from '../../images/quenouille2.png'
import Img4 from '../../images/scion.png'
import Img5 from '../../images/fleur-de-france.png'
import Img6 from '../../images/PB_HVE_MENTION_COULEUR.png'

import { Link } from "react-router-dom";

const PepinieristeSudOuest = () => {
    const [allProducts, setAllProducts] = useState([]);

    useEffect(() => {
        getAllProducts().then((res: any) => {
            setAllProducts(res)
        })
    }, [])

    return (
        <div className="nc-PageHome relative overflow-hidden">
            <Helmet>
                <title>Pépiniériste dans le Sud Ouest - fruitiers-anciens.fr</title>
            </Helmet>

            <div className="bg-[#e7d9c7] py-2">
                <div className="flex flex-row container lg:px-16 xl:px-16 md:px-16">
                    <Link to="/" className="underline cursor-pointer font-[500]">Accueil</Link>
                    <span className="ml-2 mr-2">{">"}</span>
                    <h1>Pépiniériste dans le Sud Ouest</h1>
                </div>
            </div>

            <div className="bg-[white]">
                <div className={`container`}>
                    <div className={`flow-root`}>
                        <div className="flex text-3xl lg:px-8 justify-center flex-col py-8 leading-[25px]">

                            <div className="mb-16 flex justify-center mt-14 leading-[40px]">
                                <h1 className="text-[#725E39] font-semibold text-[40px]">Pépiniériste dans le Sud Ouest</h1>
                            </div>

                            <div>
                                <div className="border-l-2 border-green-700 mb-4">
                                    <p className="text-[18px] text-[#555] ml-2">Abricotier, amandier, cerisier, cognassier ou encore prunier et pêcher, notre entreprise familiale vous propose dix espèces d’arbres fruitiers et plus de 140 variétés. Installée dans un terroir propice au développement des arbres fruitiers, notre pépinière vous propose de nombreux produits.</p>
                                </div>
                                <div className="mt-10 mb-6">
                                    <h1 className="text-[#725E39] text-[24px]">L’histoire de la pépinière Saint-Romas</h1>
                                </div>

                                <p className="text-[15px]">C’est depuis 1932 que notre famille produit de nombreuses espèces fruitières. Fondée par Germain Saint-Romas, grand-père de l’actuel gérant, il a transmis ses connaissances et son savoir-faire de génération en génération.
                                    <br />Pour votre plus grande satisfaction, votre pépiniériste dans le Sud-Ouest vous propose des arbres fruitiers de qualité. Produits dans le Tarn-et-Garonne, nos pépinières Saint-Romas sont inscrites dans des démarches agroenvironnementales et de label de qualité à savoir : Plante bleue et fleur de France
                                </p>



                                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 mt-6 text-[15px] gap-4 items-center h-full">

                                    <div>
                                        <img src={Img1} />
                                    </div>
                                    <div className="col-span-2">
                                        <p>Et c'est la 3ème génération à faire des arbres fruitiers auprès des professionnels et maintenant des particuliers.<br />
                                            La pépinière se situe dans un endroit favorable, de par la terre et le climat du Tarn et Garonne. Cela permet d’avoir des racines très fines et très chevelues. C’est un vrai avantage pour la reprise de la plantation. Un environnement propice et des méthodes de travail qui permettent d’assurer l’authenticité des arbres.
                                        </p>
                                        <p className="mt-6">Pour savourer des fruits frais directement dans votre jardin et ce au fil des saisons, découvrez notre vente d’arbres fruitiers dans le Tarn-et-Garonne. Afin de satisfaire tous les goûts et répondre à toutes les envies, votre pépinière d’arbres fruitiers dans le Sud-Ouest, propose les espèces principales de fruits ainsi que des variétés de fruitiers anciens: <span className="text-[#1e6132] font-semibold">Abricotier, Pêcher, Cerisier, Poirier, Cognassier, Pommier, Nectarinier, Prunier et Châtaignier</span></p>
                                    </div>

                                </div>

                                <div className="mt-10 mb-6">
                                    <h1 className="text-[#725E39] text-[24px]">Bien respecter le temps de production</h1>
                                </div>

                                <p className="text-[15px]">Grâce à nos nombreuses compétences dans le domaine, nous pourrons vous fournir de précieux conseils pour assurer l’entretien de vos végétaux. Selon l’arbre fruitier, le cycle de production varie de 18 à 36 mois. Notre savoir-faire nous permet de travailler sur des productions en racines nues et conteneurs déclinés en scion, gobelet ou ½ tige. <span className="text-[#2699FB] hover:underline cursor-pointer">Pour en savoir plus, visitez nos pages Conseils</span></p>

                                <div className="grid grid-cols-3 mt-14 place-items-center">
                                    <img src={Img2} />
                                    <img src={Img3} />
                                    <img src={Img4} />
                                </div>



                                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 mt-20 mb-6">
                                    <div className="flex flex-row">
                                        <img src={Img5} />
                                        <img className="ml-6" src={Img6} />
                                    </div>
                                    <div className="col-span-2">
                                        <p className="text-[15px]">Notre pépinière d’arbres fruitiers anciens dans le Sud-Ouest, s’étend sur une surface plein champs de 10 hectares et 1.5 hectares de conteneurs. Nous assurons une mise en culture annuelle de 45000 porte-greffes et 25000 conteneurs. Votre satisfaction étant notre priorité, nous mettons un point d’honneur à proposer des produits de qualité, pour cela nos pépinières sont certifiées par divers labels de qualité et suivent les démarches agroenvironnementales.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>





                    {/* SLIDER ALL PRODUCTS */}
                    {allProducts.length !== 0 &&
                        <SectionSliderProductCard
                            heading="Les plantes du moment"
                            data={allProducts}
                        />
                    }


                </div>
            </div>

        </div>
    );
}

export default PepinieristeSudOuest;
