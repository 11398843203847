import { Dialog, Transition } from "@headlessui/react";
import { getAnonymousCart, getProductInCart, patchCart } from "actions/cart";
import { addOrder, getOrderPreview } from "actions/orders";
import { getAllRegions } from "actions/regions";
import { getAllShippingAddress } from "actions/user";
import NcInputNumber from "components/NcInputNumber";
import Prices from "components/Prices";
import { Product } from "data/data";
import "leaflet/dist/leaflet.css";
import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Input from "shared/Input/Input";
import PlaceIcon from "shared/NcImage/PlaceIcon";
import countries from "utils/countries.json";
import GLSParcelShopsMap from '../../components/GLSParcelShopsMap';
import { IMG_LINK } from "../../config/key";
import { getStripeClientSecret } from "actions/stripe";

const CheckoutDelivery = () => {
    const [cart, setCart]: any = useState<Array<Product>>([]);
    const [successDeleteProductInCart, setSuccessDeleteProductInCart] = useState(false)
    const dispatch = useDispatch();
    const { auth }: any = useSelector(state => state)
    const { cartLength }: any = useSelector((state: any) => state.cart);
    const [glsRelayData, setGLSRelayData] = useState({});
    const [glsRelayDataFinal, setGLSRelayDataFinal]: any = useState({});
    const [collaspeOrderSummary, setCollaspeOrderSummary] = useState(false);

    const cartAnonymous: any = useSelector(state => state)
    const history = useHistory();
    const [allShippingAddress, setAllShippingAddress]: any = useState([]);
    const [allRegionsDelivery, setAllRegionsDelivery]: any = useState([]);
    const [methodDelivery, setMethodDelivery]: any = useState([]);
    const [chooseMethodDelivery, setChooseMethodDelivery]: any = useState(0);
    const [shippingAddressId, setShippingAddressId]: any = useState();
    const [shippingAddress, setShippingAddress]: any = useState()

    const [orderPreview, setOrderPreview]: any = useState([]);
    const [discountResponse, setDiscountResponse]: any = useState([]);
    const [discountCode, setDiscountCode]: any = useState();
    const [showModalIframeMondialRelay, setShowModalIframeMondialRelay]: any = useState('');
    const [mondialRelayData, setMondialRelayData]: any = useState();
    const [selectedDeliveryIndex, setSelectedDeliveryIndex] = useState(0);
    const [selectedDeliveryId, setSelectedDeliveryId] = useState();
    const [showModalIframeGLS, setShowModalIframeGLS] = useState(false);
    let location: any = useLocation();

    const postalCode = location?.state?.postalCode;
    const city = location?.state?.city;

    useEffect(() => {
        function handleWindowResize() {
            const isMobile = window.innerWidth <= 768; // Largeur considérée comme mobile (ajustez cette valeur selon vos besoins)
            setCollaspeOrderSummary(!isMobile);
        }

        handleWindowResize(); // Vérifier l'état initial lors du rendu initial

        window.addEventListener('resize', handleWindowResize); // Ajouter un écouteur d'événement pour détecter les changements de taille d'écran

        return () => {
            window.removeEventListener('resize', handleWindowResize); // Nettoyer l'écouteur d'événement lors du démontage du composant
        };
    }, []);

    useEffect(() => {
        if (Object.keys(auth).length === 0 || (auth.token === null && auth.user === null)) {
            history.push("/cart");
        } else if (location?.pathname === "/checkout/delivery" && (!cartLength || cartLength === 0)) {
            history.push("/cart");
        }
    }, [])

    useEffect(() => {
        setSuccessDeleteProductInCart(false)

        const fetchData = async () => {
            const data = await dispatch(getProductInCart());
            setCart(data);
        }

        fetchData();
    }, [successDeleteProductInCart])

    useEffect(() => {
        if (cartAnonymous?.cart?._id) {
            dispatch(getAnonymousCart(cartAnonymous?.cart?._id)).then((res: any) => {
                setCart(res);
            })
        }
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            const data = await getAllShippingAddress();
            const dataRegions = await getAllRegions()
            setAllShippingAddress(data.shippingAddress);
            setAllRegionsDelivery(dataRegions?.data)

            data?.shippingAddress.map((it: any, index: number) => {
                if (it.defaut === true) {
                    setShippingAddressId(it._id);
                    setShippingAddress(it)
                }
            })
        }


        fetchData();
    }, []);

    useEffect(() => {
        if (allShippingAddress) {
            allShippingAddress.map((it: any) => {
                if (it.defaut === true) {
                    const test = countries.filter(function (item) { return item?.name === it?.country; });
                    const filteredMethods = allRegionsDelivery?.filter((item: any) => item?.country?.includes(test[0]?.code));
                    setMethodDelivery(filteredMethods)
                }
            })
        }
    }, [allShippingAddress])

    useEffect(() => {
        if (auth?.token) {
            const fetchData = async () => {
                await getOrderPreview().then((data: any) => {
                    setOrderPreview(data)
                })
            }

            fetchData();
        }
    }, [discountResponse]);

    useEffect(() => {
        setMondialRelayData((window as any).relayData);
    }, [(window as any).relayData])

    const handleAddDiscountCode = async () => {

        const data = {
            discountCode: discountCode
        }

        await dispatch(patchCart(data)).then((res: any) => {
            setDiscountResponse(res);
        }).catch((err: any) => {
            NotificationManager.error(err?.response?.data?.error, '');
        })
    }

    const handleDeleteDiscountCode = async () => {

        const data = {
            discountCode: -1
        }

        await dispatch(patchCart(data)).then((res: any) => {
            setDiscountResponse(res)
        })
    }

    const renderProduct = () => {
        return (
            <>
                {cart?.item &&

                    <>
                        {Object.keys(cart?.item).map((item: any, index: number) => (

                            <div
                                key={index}
                                className="relative flex py-8 sm:py-10 xl:py-5 first:pt-0 last:pb-0"
                            >
                                <div className="relative h-20 w-20 sm:w-40 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">

                                    <div key={index}>
                                        {cart?.item[item]?.itemRef?.images?.image1Id
                                            ?
                                            <>
                                                <img
                                                    className="h-full w-full "
                                                    src={IMG_LINK + cart?.item[item]?.itemRef?.images?.image1Id}
                                                    alt={item.name}
                                                />
                                            </>
                                            :
                                            <div className="nc-NcImage flex aspect-w-11 aspect-h-12 w-full h-0">
                                                <div className={`flex items-center justify-center bg-neutral-200 dark:bg-neutral-6000 text-neutral-100 dark:text-neutral-500`}>
                                                    <div className="h-2/4 max-w-[50%]">
                                                        <PlaceIcon />
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>

                                </div>

                                <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
                                    <div>
                                        <div className="flex justify-between ">
                                            <div className="flex-[1.5] ">
                                                <h3 className="text-base font-semibold">
                                                    <Link to="/product-detail">{cart?.item[item]?.name}</Link>
                                                </h3>
                                                <div className="mt-1.5 sm:mt-2.5 flex text-sm text-slate-600 dark:text-slate-300">
                                                    <div>
                                                        {cart?.item[item]?.itemRef?.properties &&
                                                            <>
                                                                {Object.keys(cart?.item[item]?.itemRef?.properties).map((propertie: any, index: number) => (
                                                                    <div key={index}>
                                                                        <div><span className="capitalize">{propertie}</span>: {cart?.item[item]?.itemRef?.properties[propertie]}</div>
                                                                    </div>
                                                                ))}
                                                            </>
                                                        }

                                                    </div>
                                                </div>

                                                <div className="mt-1 flex justify-between w-full sm:hidden relative">
                                                    <span className="text-sm text-slate-600">Quantité: x{cart?.item[item]?.quantity}</span>

                                                    <div className="sm:block text-center relative">

                                                        {cart?.item[item]?.priceWithDiscount !== undefined && cart?.item[item]?.priceWithDiscount !== cart?.item[item]?.price ?
                                                            <>
                                                                <div className="flex flex-row">
                                                                    <div className="mr-1">
                                                                        <Prices
                                                                            key={index}
                                                                            contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                                                                            price={cart?.item[item]?.priceWithDiscount}
                                                                        />
                                                                    </div>
                                                                    <div className="line-through text-[#e74c3c]">
                                                                        {cart?.item[item]?.price} €
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                {cart?.item[item]?.rebatedPrice
                                                                    ? <div className="flex flex-row"><Prices
                                                                        key={index}
                                                                        contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                                                                        price={cart?.item[item]?.rebatedPrice}
                                                                    /><Prices
                                                                            key={index}
                                                                            contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-red-500 line-through text-sm font-medium h-full"
                                                                            price={cart?.item[item]?.price}
                                                                        /></div>
                                                                    : <Prices
                                                                        key={index}
                                                                        contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                                                                        price={cart?.item[item]?.price}
                                                                    />
                                                                }
                                                            </>
                                                        }

                                                    </div>
                                                </div>
                                            </div>



                                            <div className="hidden sm:block text-center relative">
                                                <NcInputNumber className="relative z-10" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                }
            </>

        );
    };

    const handleChooseDelivery = (index: any, id: any) => {
        setSelectedDeliveryIndex(index);
        setSelectedDeliveryId(id);
    }

    useEffect(() => {
        setSelectedDeliveryId(methodDelivery[0]?._id)
    }, [methodDelivery])

    const handleCollapseOrderSummary = () => {
        setCollaspeOrderSummary(true)

        if (collaspeOrderSummary) {
            setCollaspeOrderSummary(false);
        }
    }

    const handleAddOrder = async () => {
        let data: object = {
            shippingAddress: shippingAddressId,
            deliveryId: selectedDeliveryIndex,
            regionId: selectedDeliveryId,
            relayId: mondialRelayData?.ID || glsRelayDataFinal?.id
        }

        await dispatch(patchCart(data)).then((res: any) => {
            delete (window as any).relayData;

            getStripeClientSecret().then((clientSecret) => {
                history.push({ pathname: './payment', state: { orderRecap: res, secretKey: clientSecret, orderSummary: cart, methodDelivery: methodDelivery?.delivery, chooseMethodDelivery: chooseMethodDelivery, deliveryPrice: getMatchingObject(cart?.weight, methodDelivery[0]?.delivery[selectedDeliveryIndex]?.price) === null ? methodDelivery[0]?.delivery[selectedDeliveryIndex]?.defaultPrice : getMatchingObject(cart?.weight, methodDelivery[0]?.delivery[selectedDeliveryIndex]?.price), totalOrder: orderPreview?.discountedPrice ? orderPreview?.discountedPrice : orderPreview?.price } })
            })

        }).catch((err: any) => {
            NotificationManager.error(err.response.data.error, '');
        })
    }

    const mondialRelayIframe = () => {
        return (
            <>
                <Helmet>

                    <script data-value="test">
                        {`
                    $("#Zone_Widget").MR_ParcelShopPicker({
                        Target: "#Target_Widget",
                        TargetDisplay: "#TargetDisplay_Widget",
                        TargetDisplayInfoPR: "#TargetDisplayInfoPR_Widget",
                        Brand: "BDTEST  ",
                        Country: "FR",
                        PostCode: "${postalCode}",
                        City: "${city}",
                        ColLivMod: "24R",
                        NbResults: "7",
                        reponsive: true,
                        ShowResultsOnMap: true,
                        DisplayMapInfo: true,
                        OnParcelShopSelected:
                            function (data) {                          
                                window.relayData = data
                            }
                      });
                    `}
                    </script>
                </Helmet>

                <div className="mb-4" id="Zone_Widget"></div>
            </>
        )

    }

    const handleShowModalIframeMondialRelay = () => {
        if (!showModalIframeMondialRelay) {
            setShowModalIframeMondialRelay(true)
        }
    }

    const handleShowModalIframeGLS = () => {
        if (!showModalIframeGLS) {
            setShowModalIframeGLS(true)
        }
    }

    const getDeliveryPrice = (weight: any, threshold: any) => {
        let price = threshold.defaultPrice;
        threshold = threshold.price
        let selectedThreshold = null

        for (let i = 0; i <= threshold.length - 1; i++) {
            if (weight >= threshold[i].weight) {
                selectedThreshold = threshold[i];
                price = threshold[i].price
            }
        }

        if (price === 0) {
            return "Gratuit"
        }

        return price + ' €'
    }

    const getMatchingObject = (weight: any, objects: any) => {
        let matchingObject = null;

        if (Array.isArray(objects) && objects.length > 0) {
            for (let i = 0; i < objects.length; i++) {
                if (weight >= objects[i].weight) {
                    matchingObject = objects[i]?.price;
                }
            }
        }

        return matchingObject;
    };

    const handleChooseGLSRelayPoint = () => {
        if (Object.keys(glsRelayData).length !== 0) {
            setShowModalIframeGLS(false);
            setGLSRelayDataFinal(glsRelayData)
        } else {
            NotificationManager.error("Merci de bien vouloir sélectionnez un Point Relais® !", '');
        }
    }

    const handlePickUpMondialRelayPoint = () => {
        setShowModalIframeMondialRelay(false);
    }

    return (
        <div className="nc-CartPage">
            <Helmet>
                <title>Tunnel d'achat | fruitiers-anciens.fr</title>
            </Helmet>

            <main className="container py-16 lg:pb-28 lg:pt-20 ">
                <div>
                    <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
                        Tunnel d'achat
                    </h2>
                    <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
                        Informations
                        <span className="text-xs mx-1 sm:mx-1.5">/</span>
                        <span className="underline">Livraison</span>
                        <span className="text-xs mx-1 sm:mx-1.5">/</span>
                        Paiement
                    </div>
                </div>

                <hr className="border-slate-200 dark:border-slate-700 my-10 xl:my-12 hidden lg:block xl:block" />

                <div className="flex flex-col lg:flex-row">
                    <div className="w-full lg:w-[60%] xl:w-[55%] order-2 lg:order-none xl:order-none">
                        <div className="border-[1px] p-4 rounded text-[14px] w-full">
                            <div className="grid grid-cols-3">
                                <div className="flex justify-start">
                                    <span className="text-gray-400 font-[500]">Contact :</span>
                                </div>
                                <div className="">
                                    <span className="text-left">{auth?.user?.email}</span>
                                </div>
                                <div className="flex justify-end">
                                    <Link to={'../account'} className="cursor-pointer hover:underline flex justify-end relative z-10 font-medium text-primary-6000 hover:text-primary-500 text-sm">Modifier</Link>
                                </div>
                            </div>

                            <hr className="mt-2 mb-2" />

                            <div className="grid grid-cols-3">
                                <div className="flex justify-start">
                                    <span className="text-gray-400 font-[500]">Expédition :</span>
                                </div>
                                <div className="">
                                    <span className="text-left">
                                        {allShippingAddress.map((it: any, index: number) => (
                                            <div key={index} className="lg:whitespace-nowrap xl:whitespace-nowrap md:whitespace-nowrap">
                                                {it.defaut === true &&
                                                    <h1>{it?.address} {it?.city}, {it?.country}</h1>
                                                }
                                            </div>
                                        ))}
                                    </span>
                                </div>
                                <div className="flex justify-end">
                                    <Link to={'../shipping-address'} className="cursor-pointer hover:underline flex justify-end relative z-10 font-medium text-primary-6000 hover:text-primary-500 text-sm">Modifier</Link>
                                </div>
                            </div>
                        </div>

                        <div className="mt-10">
                            <h1 className="font-[500] text-[18px] mb-4">Mode d'expédition</h1>

                            {methodDelivery && Object.keys(methodDelivery).map((key, index) => (
                                <div key={index} className="">

                                    {methodDelivery[index]?.delivery.map((item: any, i: number) => (

                                        <div key={i}>
                                            <div onClick={() => handleChooseDelivery(i, methodDelivery[index]?._id)} className={`rounded border-[1px] mt-2 p-4 cursor-pointer`}>
                                                <div className="flex justify-between">
                                                    <div className="flex flex-row">
                                                        <div className="flex justify-center items-center">
                                                            <input
                                                                type="radio"
                                                                id={`radio-${index}-${i}`}
                                                                name={`radio-${index}`}
                                                                value={item.shipperName}
                                                                onChange={() => { }}
                                                                checked={i === selectedDeliveryIndex && selectedDeliveryId === methodDelivery[index]?._id}
                                                            />
                                                        </div>
                                                        <div className="ml-4 flex flex-col">
                                                            <span className="mt-[.2rem]">{item?.name}</span>
                                                            <span className="mt-[.2rem]">{item?.description}</span>
                                                        </div>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <span className={`rounded-lg px-2`}>
                                                            <span className="font-semibold">{getDeliveryPrice(cart?.weight, item)}</span>
                                                        </span>
                                                    </div>
                                                </div>

                                                {(i === selectedDeliveryIndex && selectedDeliveryId === methodDelivery[index]?._id) &&
                                                    <>
                                                        {item.shipperName === "MondialRelay" && item.relayPoint === true &&
                                                            <>
                                                                {mondialRelayData &&
                                                                    <>
                                                                        <hr className="mt-4 mb-4" />
                                                                        <div className="mb-2 font-semibold">Point Relais® sélectionné:  </div>
                                                                        <div className="ml-4">{mondialRelayData?.Nom}</div>
                                                                        <div className="ml-4">{mondialRelayData?.Adresse1}</div>
                                                                        <div className="ml-4">{mondialRelayData?.Ville}, {mondialRelayData?.CP} {mondialRelayData?.Pays}</div>
                                                                    </>
                                                                }
                                                            </>
                                                        }

                                                        {item.shipperName === "GLS PointRelay" && item.relayPoint === true &&
                                                            <>
                                                                {Object.keys(glsRelayDataFinal).length !== 0 &&
                                                                    <>
                                                                        <hr className="mt-4 mb-4" />
                                                                        <div className="mb-2 font-semibold">Point Relais® sélectionné:  </div>
                                                                        <div className="ml-4">{glsRelayDataFinal?.address?.Name1}</div>
                                                                        <div className="ml-4">{glsRelayDataFinal?.address?.Street}</div>
                                                                        <div className="ml-4">{glsRelayDataFinal?.address?.City}, {glsRelayDataFinal?.address?.ZIPCode} {glsRelayDataFinal?.address?.CountryCode}</div>
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                }

                                            </div>


                                            {(i === selectedDeliveryIndex && selectedDeliveryId === methodDelivery[index]?._id) &&
                                                <>
                                                    {item.shipperName === "MondialRelay" && item.relayPoint === true &&

                                                        <div>
                                                            <div onClick={() => handleShowModalIframeMondialRelay()} className="bg-[#74a534] rounded text-[white] text-[14px] p-2 text-center cursor-pointer">
                                                                {mondialRelayData ? "Modifier votre Point Relais®" : "Sélectionnez votre Point Relais®"}
                                                            </div>
                                                        </div>
                                                    }

                                                    {item.shipperName === "GLS PointRelay" && item.relayPoint === true &&
                                                        <div>
                                                            <div onClick={() => handleShowModalIframeGLS()} className="bg-[#74a534] rounded text-[white] text-[14px] p-2 text-center cursor-pointer">
                                                                {Object.keys(glsRelayDataFinal).length !== 0 ? "Modifier votre Point Relais®" : "Sélectionnez votre Point Relais®"}

                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            }

                                        </div>
                                    ))}
                                </div>
                            ))}
                            <div className="w-full flex justify-end">

                                <div className="flex justify-between mt-4 hidden lg:block xl:block">
                                    <span onClick={() => history.push("./information")} className="whitespace-nowrap cursor-pointer mt-7 relative z-10 font-medium text-primary-6000 hover:text-primary-500 text-sm">
                                        {"<"} <span className="cursor-pointer hover:underline">Revenir aux informations</span>
                                    </span>
                                    <ButtonPrimary onClick={() => handleAddOrder()} className="h-1/4 mt-4 ml-4">
                                        Continuer
                                    </ButtonPrimary>
                                </div>
                            </div>
                        </div   >
                    </div>

                    <>
                        <div className="border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-0 lg:my-0 lg:mx-10 flex-shrink-0 mt-10 mb-10"></div>
                        <div className="flex-1 order-1 lg:order-none xl:order-none">
                            <div className="">
                                <h3 className="text-lg font-semibold mb-4">Récapitulatif de la commande</h3>

                                <div className="border-[1px] rounded p-3">
                                    <div className="flex justify-between font-[400]">
                                        <span className="text-slate-500">{cart?.cartLength} {cart?.cartLength === 1 ? "article" : "articles"} dans votre panier - {cart?.discountedPrice ? cart?.discountedPrice?.toFixed(2) : cart?.priceTotal?.toFixed(2)} €</span>
                                        <span onClick={() => handleCollapseOrderSummary()} className="font-[500] text-primary-6000 text-[14px] hover:underline hover:text-primary-500 cursor-pointer">{collaspeOrderSummary ? "Fermer le détail" : "Voir le détail"}</span>
                                    </div>
                                </div>

                                {collaspeOrderSummary &&
                                    <div className="mt-8">
                                        {renderProduct()}
                                        <div className="block lg:hidden xl:hidden">

                                            <div className="flex justify-between pb-4">
                                                <span className="mt-5">Sous-total ({cart?.cartLength} {cart?.cartLength === 1 ? "article" : "articles"})</span>
                                                <span className="font-semibold text-slate-900 dark:text-slate-200 mt-5">
                                                    {cart?.discountedPriceWithoutTaxe ? cart?.discountedPriceWithoutTaxe : cart?.cartPriceWithoutTaxe?.toFixed(2)} €
                                                </span>
                                            </div>

                                            {orderPreview.discount &&
                                                <div className="flex justify-between pb-4">
                                                    <span className="mt-4">Remise ({orderPreview?.discount?.discountCode})</span>
                                                    <span className="font-semibold text-slate-900 dark:text-slate-200 mt-4">
                                                        - {orderPreview?.discount?.amount}
                                                        {orderPreview?.discount?.type === "percentage" ? "%" : "€"}
                                                    </span>
                                                    <span className="mt-4 cursor-pointer" onClick={() => handleDeleteDiscountCode()}>
                                                        <button className="w-5 h-5 flex items-center justify-center rounded-full bg-slate-200 dark:bg-slate-900 text-neutral-700 dark:text-slate-200 nc-shadow-lg ">
                                                            <span className="text-[12px]">x</span>
                                                        </button>
                                                    </span>
                                                </div>
                                            }

                                            <div className="flex justify-between pb-4">
                                                <span className="mt-5">Livraison</span>
                                                <span className="font-semibold text-slate-900 dark:text-slate-200 mt-5">
                                                    {methodDelivery &&
                                                        <>
                                                            {(parseFloat(getMatchingObject(cart?.weight, methodDelivery[0]?.delivery[selectedDeliveryIndex]?.price) === null ? methodDelivery[0]?.delivery[selectedDeliveryIndex]?.defaultPrice : getMatchingObject(cart?.weight, methodDelivery[0]?.delivery[selectedDeliveryIndex]?.price)?.toFixed(2))).toFixed(2)} €
                                                        </>
                                                    }
                                                </span>
                                            </div>

                                            <div className="flex justify-between pb-4">
                                                <span className="mt-4">Dont TVA</span>
                                                <span className="font-semibold text-slate-900 dark:text-slate-200 mt-4">
                                                    {cart?.taxePrice?.toFixed(2)} €
                                                </span>
                                            </div>

                                            <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                                                <div>
                                                    <span>Total de la commande</span>
                                                    <p className="text-slate-500 text-xs font-normal">Taxes incluses</p>
                                                </div>

                                                {((Number(cart?.discount?.amount ? (cart?.discountedPrice)?.toFixed(2) : cart?.cartPrice)) + parseFloat(getMatchingObject(cart?.weight, methodDelivery[0]?.delivery[selectedDeliveryIndex]?.price) === null ? methodDelivery[0]?.delivery[selectedDeliveryIndex]?.defaultPrice : getMatchingObject(cart?.weight, methodDelivery[0]?.delivery[selectedDeliveryIndex]?.price)?.toFixed(2)))?.toFixed(2)}

                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="hidden lg:block xl:block">


                                    <div className="flex justify-between pb-4">
                                        <span className="mt-5">Sous-total ({cart?.cartLength} {cart?.cartLength === 1 ? "article" : "articles"})</span>
                                        <span className="font-semibold text-slate-900 dark:text-slate-200 mt-5">
                                            {cart?.discountedPriceWithoutTaxe ? cart?.discountedPriceWithoutTaxe : cart?.cartPriceWithoutTaxe?.toFixed(2)} €
                                        </span>
                                    </div>

                                    {orderPreview.discount &&
                                        <div className="flex justify-between pb-4">
                                            <span className="mt-4">Remise ({orderPreview?.discount?.discountCode})</span>
                                            <span className="font-semibold text-slate-900 dark:text-slate-200 mt-4">
                                                - {orderPreview?.discount?.amount}
                                                {orderPreview?.discount?.type === "percentage" ? "%" : "€"}
                                            </span>
                                            <span className="mt-4 cursor-pointer" onClick={() => handleDeleteDiscountCode()}>
                                                <button className="w-5 h-5 flex items-center justify-center rounded-full bg-slate-200 dark:bg-slate-900 text-neutral-700 dark:text-slate-200 nc-shadow-lg ">
                                                    <span className="text-[12px]">x</span>
                                                </button>
                                            </span>
                                        </div>
                                    }

                                    <div className="flex justify-between pb-4">
                                        <span className="mt-5">Livraison</span>
                                        <span className="font-semibold text-slate-900 dark:text-slate-200 mt-5">
                                            {methodDelivery &&
                                                <>
                                                    {(parseFloat(getMatchingObject(cart?.weight, methodDelivery[0]?.delivery[selectedDeliveryIndex]?.price) === null ? methodDelivery[0]?.delivery[selectedDeliveryIndex]?.defaultPrice : getMatchingObject(cart?.weight, methodDelivery[0]?.delivery[selectedDeliveryIndex]?.price)?.toFixed(2))).toFixed(2)} €
                                                </>
                                            }
                                        </span>
                                    </div>

                                    <div className="flex justify-between pb-4">
                                        <span className="mt-4">Dont TVA</span>
                                        <span className="font-semibold text-slate-900 dark:text-slate-200 mt-4">
                                            {cart?.taxePrice?.toFixed(2)} €
                                        </span>
                                    </div>

                                    <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                                        <div>
                                            <span>Total de la commande</span>
                                            <p className="text-slate-500 text-xs font-normal">Taxes incluses</p>
                                        </div>

                                        {((Number(cart?.discount?.amount ? (cart?.discountedPrice)?.toFixed(2) : cart?.cartPrice)) + parseFloat(getMatchingObject(cart?.weight, methodDelivery[0]?.delivery[selectedDeliveryIndex]?.price) === null ? methodDelivery[0]?.delivery[selectedDeliveryIndex]?.defaultPrice : getMatchingObject(cart?.weight, methodDelivery[0]?.delivery[selectedDeliveryIndex]?.price)?.toFixed(2)))?.toFixed(2)}

                                    </div>
                                </div>

                                <div className="border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-0 lg:my-0 lg:mx-10 flex-shrink-0 mt-10 mb-10 block lg:hidden xl:hidden"></div>

                                <div className="mt-7 text-sm text-slate-500 dark:text-slate-400 divide-y divide-slate-200/70 dark:divide-slate-700/80">

                                </div>
                            </div>
                        </div>
                    </>
                </div>

                <div className="flex justify-between mt-4 block lg:hidden xl:hidden">
                    <span onClick={() => history.push("./information")} className="whitespace-nowrap cursor-pointer mt-7 relative z-10 font-medium text-primary-6000 hover:text-primary-500 text-sm">
                        {"<"} <span className="cursor-pointer hover:underline">Revenir aux informations</span>
                    </span>
                    <ButtonPrimary onClick={() => handleAddOrder()} className="h-1/4 mt-4 w-full ml-4">
                        Continuer
                    </ButtonPrimary>
                </div>
            </main>

            <Transition appear show={showModalIframeGLS ? true : false} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-50 h-full w-full"
                    onClose={() => setShowModalIframeGLS(false)}
                >
                    <Transition.Child
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black/40 dark:bg-black/70" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="inline-block align-middle" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="flex justify-center items-center mt-20 w-full h-full">
                            <div className="text-center bg-[white] rounded px-6 py-4 w-[900px]">
                                <div className="flex justify-end relative right-[-15px] top-[-5px]">
                                    <ButtonClose onClick={() => setShowModalIframeGLS(false)} />
                                </div>
                                <h1 className="mb-5">Sélectionnez votre Point Relais® ou Locker</h1>

                                <GLSParcelShopsMap onChooseGLSRelayPoint={handleChooseGLSRelayPoint} address={shippingAddress} onMarkerClick={(id: any, address: any) => setGLSRelayData({ id: id, address: address })} />


                                {/* <div className="flex justify-end mt-4">
                                    <ButtonPrimary onClick={() => { handleChooseGLSRelayPoint() }}>
                                        Choisir ce Point Relais®
                                    </ButtonPrimary>
                                </div> */}
                            </div>
                        </div>
                    </Transition.Child>
                </Dialog>
            </Transition>

            <Transition appear show={showModalIframeMondialRelay ? true : false} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-50"
                    onClose={() => setShowModalIframeMondialRelay(false)}
                >
                    <Transition.Child
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black/40 dark:bg-black/70" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="inline-block align-middle" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="flex justify-center items-center mt-20">
                            <div className="text-center bg-[white] rounded px-10 py-4">
                                <div className="flex justify-end relative right-[-25px] top-[-5px]">
                                    <ButtonClose onClick={() => setShowModalIframeMondialRelay(false)} />
                                </div>
                                <h1 className="mb-5">Sélectionnez votre Point Relais® ou Locker</h1>

                                {mondialRelayIframe()}

                                <div className="flex justify-end">
                                    <ButtonPrimary onClick={() => handlePickUpMondialRelayPoint()}>
                                        Choisir ce Point Relais®
                                    </ButtonPrimary>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </Dialog>
            </Transition>
        </div>
    );
};

export default CheckoutDelivery;

