import NcInputNumber from "components/NcInputNumber";
import Prices from "components/Prices";
import { IMG_LINK } from "config/key";
import "leaflet/dist/leaflet.css";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import PlaceIcon from "shared/NcImage/PlaceIcon";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ButtonPrimary from "shared/Button/ButtonPrimary";

const ConfirmPayment = () => {
    const history: any = useHistory();
    const order = history?.location?.state?.order?.order;
    const [collaspeOrderSummary, setCollaspeOrderSummary] = useState(true);
    const { auth }: any = useSelector(state => state);

    useEffect(() => {
        if (!history?.location?.state) {
            history.push('/');
        }
    }, []);

    const handleCollapseOrderSummary = () => {
        setCollaspeOrderSummary(true)

        if (collaspeOrderSummary) {
            setCollaspeOrderSummary(false);
        }
    }

    const renderProduct = () => {
        return (
            <>
                {order?.item &&

                    <>
                        {Object.keys(order?.item).map((item: any, index: number) => (

                            <div
                                key={index}
                                className="relative flex py-8 sm:py-10 xl:py-5 first:pt-0 last:pb-0"
                            >
                                <div className="relative h-20 w-20 sm:w-40 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">

                                    <div key={index}>
                                        {order?.item[item]?.itemRef?.images?.thumbnailId
                                            ?
                                            <>
                                                <img
                                                    className="flex h-full w-full object-cover"
                                                    src={IMG_LINK + order?.item[item]?.itemRef?.images?.thumbnailId}
                                                    alt={item.name}
                                                />
                                            </>
                                            :
                                            <div className="nc-NcImage flex aspect-w-11 aspect-h-12 w-full h-0">
                                                <div className={`flex items-center justify-center bg-neutral-200 dark:bg-neutral-6000 text-neutral-100 dark:text-neutral-500`}>
                                                    <div className="h-2/4 max-w-[50%]">
                                                        <PlaceIcon />
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>

                                </div>

                                <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
                                    <div>
                                        <div className="flex justify-between ">
                                            <div className="flex-[1.5] ">
                                                <h3 className="text-base font-semibold">
                                                    <Link to="/product-detail">{order?.item[item]?.name}</Link>
                                                </h3>
                                                <div className="mt-1.5 sm:mt-2.5 flex text-sm text-slate-600 dark:text-slate-300">
                                                    <div>
                                                        {order?.item[item]?.itemRef?.properties &&
                                                            <>
                                                                {Object.keys(order?.item[item]?.itemRef?.properties).map((propertie: any, index: number) => (
                                                                    <div key={index}>
                                                                        <div><span className="capitalize">{propertie}</span>: {order?.item[item]?.itemRef?.properties[propertie]}</div>
                                                                    </div>
                                                                ))}
                                                            </>
                                                        }

                                                    </div>
                                                </div>

                                                <div className="mt-1 flex justify-between w-full sm:hidden relative">
                                                    <span className="text-sm text-slate-600">Quantité: x{order?.item[item]?.quantity}</span>

                                                    <div className="sm:block text-center relative">

                                                        {order?.item[item]?.priceWithDiscount !== undefined && order?.item[item]?.priceWithDiscount !== order?.item[item]?.price ?
                                                            <>
                                                                <div className="flex flex-row">
                                                                    <div className="mr-1">
                                                                        <Prices
                                                                            key={index}
                                                                            contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                                                                            price={order?.item[item]?.priceWithDiscount}
                                                                        />
                                                                    </div>
                                                                    <div className="line-through text-[#e74c3c]">
                                                                        {order?.item[item]?.price} €
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                {order?.item[item]?.rebatedPrice
                                                                    ? <div className="flex flex-row"><Prices
                                                                        key={index}
                                                                        contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                                                                        price={order?.item[item]?.rebatedPrice}
                                                                    /><Prices
                                                                            key={index}
                                                                            contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-red-500 line-through text-sm font-medium h-full"
                                                                            price={order?.item[item]?.price}
                                                                        /></div>
                                                                    : <Prices
                                                                        key={index}
                                                                        contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                                                                        price={order?.item[item]?.price}
                                                                    />
                                                                }
                                                            </>
                                                        }

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="hidden sm:block text-center relative">
                                                <NcInputNumber className="relative z-10" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                }
            </>

        );
    };

    return (
        <div className="nc-CartPage">
            <Helmet>
                <title>Confirmation | fruitiers-anciens.fr</title>
            </Helmet>

            <main className="container py-16 lg:pb-28 lg:pt-20 ">
                <div>
                    <div className="flex justify-center mb-4">
                        <div className="m-2 flex h-14 w-14 items-center justify-center rounded-full bg-[#2ecc71] border-[2px] border-[#2ecc71]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 70.761 49.778">
                                <path fill="white" id="Check-Icon-09iujhd" d="M115.739,61.78,78.282,99.237a5.655,5.655,0,0,1-8.005,0L49.3,78.257a5.653,5.653,0,0,1,7.994-7.994L74.279,87.24l33.455-33.466a5.661,5.661,0,0,1,8.005,8.005" transform="translate(-47.133 -51.62)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" strokeWidth="1" />
                            </svg>
                        </div>
                    </div>
                    <h2 className="block text-center text-2xl sm:text-3xl lg:text-4xl font-semibold">
                        Merci <span className={`text-[#74a534] font-bold`}>{auth?.user?.name}</span>, <br />Votre commande a été enregistrée!
                    </h2>

                    <div className="flex flex-col justify-center items-center">
                        <div className="text-center flex justify-center">
                            <p className="mt-8 max-w-[75%]">
                                Pour suivre la préparation de votre commande, rendez-vous sur votre compte dans "Mes commandes" ou cliquez sur le bouton ci-dessous.
                            </p>
                        </div>
                        <div className="mt-3">
                            <ButtonPrimary onClick={() => history.push('../account-my-order')}>Voir mes commandes</ButtonPrimary>
                        </div>
                    </div>


                </div>

                <hr className="border-slate-200 dark:border-slate-700 my-10 xl:my-12 block lg:block xl:block" />



                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4">
                    <div>
                        <h3 className="text-lg text-center md:text-left lg:text-left: xl:text-left font-semibold mb-8">Informations du client</h3>

                        <div className="border-[1px] p-4 rounded text-[14px] w-full">
                            <div className="flex">
                                <div className="">
                                    <span className="text-gray-400 font-[500]">Contact:</span>
                                </div>
                                <div className="ml-2">
                                    <span className="text-left">{auth?.user?.email}</span>
                                </div>

                            </div>

                            <hr className="mt-2 mb-2" />

                            <div className="flex flex-row">
                                <div className="flex">
                                    <span className="text-gray-400 font-[500]">Méthode de livraison:</span>
                                </div>
                                <div className="ml-2">
                                    <span className="text-left">
                                        {order?.delivery?.delivery?.name} - {order?.delivery?.delivery?.shipperName}
                                    </span>
                                </div>

                            </div>

                            <hr className="mt-2 mb-2" />

                            <div className="flex flex-row">
                                <div className="">
                                    {order?.delivery?.relayId ? (
                                        <span className="text-gray-400 font-[500]">Adresse du Point Relais®:</span>
                                    ) : (
                                        <span className="text-gray-400 font-[500]">Adresse de livraison :</span>
                                    )}
                                </div>
                                <div className="">
                                    <span className="text-left ml-2">
                                        {order?.delivery?.relayPointInfo ?
                                            <>
                                                {order?.delivery?.relayPointInfo?.Address?.Name1},
                                                {' '}{order?.delivery?.relayPointInfo?.Address?.Street},
                                                {' '}{order?.delivery?.relayPointInfo?.Address?.City} {' '}{order?.delivery?.relayPointInfo?.Address?.ZIPCode},
                                                {' '}{order?.delivery?.relayPointInfo?.Address?.CountryCode}
                                            </>
                                            : <>{order?.shippingAddress?.address}, {order?.shippingAddress?.city} {order?.shippingAddress?.postalCode}, {order?.shippingAddress?.country}</>
                                        }


                                    </span>
                                </div>

                            </div>

                            <hr className="mt-2 mb-2" />

                            <div className="flex flex-row">
                                <div className="flex justify-start">
                                    <span className="text-gray-400 font-[500]">Méthode de paiement :</span>
                                </div>
                                <div className="ml-2">
                                    <span className="text-left">
                                        Stripe
                                    </span>
                                </div>

                            </div>
                        </div>

                    </div>



                    <div>
                        <div className="order-1">
                            <h3 className="text-lg text-center md:text-left lg:text-left xl:text-left font-semibold mb-8">Récapitulatif de la commande</h3>

                            <div className="border-[1px] rounded p-3">
                                <div className="flex justify-between font-[400]">
                                    <span className="text-slate-500">{order?.cartLength} {order?.cartLength === 1 ? "article" : "articles"} dans votre panier - {order?.discountedPrice ? order?.discountedPrice?.toFixed(2) : order?.price?.toFixed(2)} €</span>
                                    <span onClick={() => handleCollapseOrderSummary()} className="font-[500] text-primary-6000 text-[14px] hover:underline hover:text-primary-500 cursor-pointer">{collaspeOrderSummary ? "Fermer le détail" : "Voir le détail"}</span>
                                </div>
                            </div>

                            {collaspeOrderSummary &&
                                <div className="mt-8">

                                    {renderProduct()}

                                    <div className="mt-7 text-sm text-slate-500 dark:text-slate-400 divide-y divide-slate-200/70 dark:divide-slate-700/80">

                                        <div className="flex justify-between pb-4">
                                            <span className="mt-4">Sous-total</span>
                                            <span className="font-semibold text-slate-900 dark:text-slate-200 mt-5">
                                                {order?.discountedPriceWithoutTaxe ? order?.discountedPriceWithoutTaxe?.toFixed(2) : order?.cartPriceWithoutTaxe?.toFixed(2)} €
                                            </span>
                                        </div>

                                        {order.discount &&
                                            <>
                                                <div className="flex justify-between pb-4">
                                                    <span className="mt-4">Remise ({order?.discount?.discountCode})</span>
                                                    <span className="font-semibold text-slate-900 dark:text-slate-200 mt-4">
                                                        - {order?.discount?.amount}
                                                        {order?.discount?.type === "percentage" ? "%" : "€"}
                                                    </span>

                                                </div>
                                                <hr />
                                            </>
                                        }


                                        <div className="flex justify-between pb-4">
                                            <span className="mt-5">Frais de transport</span>
                                            <span className="font-semibold text-slate-900 dark:text-slate-200 mt-5">
                                                {order?.deliveryPrice} €
                                            </span>
                                        </div>

                                        <div className="flex justify-between pb-4">
                                            <span className="mt-4">Dont TVA</span>
                                            <span className="font-semibold text-slate-900 dark:text-slate-200 mt-4">
                                                {order?.taxePrice?.toFixed(2)} €
                                            </span>
                                        </div>


                                        <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                                            <div>
                                                <span>Total TTC</span>
                                            </div>
                                            {order?.discountedPrice ? (order?.discountedPrice + order?.deliveryPrice)?.toFixed(2) : (order?.price + order?.deliveryPrice).toFixed(2)} €
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                </div>
            </main>
        </div>
    );
};

export default ConfirmPayment;

