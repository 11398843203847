import { Helmet } from "react-helmet";
import SectionHero from "components/SectionHero/SectionHero";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import { getAllProducts } from "actions/products";
import { useState, useEffect } from "react";
import img1 from "images/diapo2.png";
import img2 from "images/img-biodiversite.png";
import ButtonFour from "shared/Button/ButtonFour";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import { useHistory } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";

const PageHome = () => {
  const history = useHistory();
  const [allProducts, setAllProducts] = useState([]);

  useEffect(() => {
    getAllProducts().then((res: any) => {
      setAllProducts(res)
    })

  }, [])

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Pépinière fruitiers anciens dans le Sud Ouest</title>
      </Helmet>

      {/* CAROUSSEL  */}
      <SectionHero />

      <div className="bg-[#74a534] py-10">
        <div className="container lg:px-40">
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 h-full items-center">
            <div className="flex justify-center mb-6 md:mb-0 lg:mb-0 xl:mb-0">
              <img className="ml-0 md:ml-10 lg:ml-10 xl:ml-10" src={img1} />
            </div>
            <div className="col-span-2 text-center md:text-left lg:text-left xl:text-left font-jost">
              <h1 className="text-[white] text-[24px] mb-6">La pépinière SAINT ROMAS</h1>
              <p className="text-[white] mb-2">Depuis 1932 notre famille produit de nombreuses espèces fruitières. Située à Corbarieu, notre pépinière propose à la vente près de 300 variétés d'arbres fruitiers de variétés anciennes. Installée en bordure de la rivière du Tarn, dans une plaine alluvionnaire, notre pépinière profite du climat, idéal au développement des arbres fruitiers, du Tarn-et-Garonne. Notre pépinière propose la vente aux professionnels comme aux particuliers.</p>
              <ButtonFour onClick={() => history.push('products/')}>Voir nos produits</ButtonFour>
            </div>
          </div>
        </div>
      </div>

      {/* SECTION HOW IT WORK */}
      <SectionHowItWork />

      <div className="bg-pat-craft">
        {/* SLIDER ALL PRODUCTS */}
        {allProducts.length !== 0 &&
          <SectionSliderProductCard
            heading="Les plantes du moment"
            data={allProducts}
          />
        }
      </div>

      <div className="bg-[#FFFFFF] py-10 mt-10 mb-10">
        <div className="container lg:px-40 ">
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 h-full items-center ">
            <div className="flex justify-center mb-6 md:mb-0 lg:mb-0 xl:mb-0 ">
              <img className="mr-0  md:mr-10 lg:mr-10 xl:mr-10" src={img2} />
            </div>
            <div className="col-span-2 text-center md:text-left lg:text-left xl:text-left font-jost md:ml-10 lg:ml-10 xl:ml-10 ">
              <p className="text-[#725e39] text-[24px] mb-6">La biodiversité dans vos jardins, le complément du verger et du potager.</p>
              <p className="text-[black] mb-7 text-[14px] leading-5 ">Sous forme de haie, en arbre et arbuste isolé ou en groupe certains végétaux jouent un rôle écologique en apportant nourriture et abri à la faune, insectes et oiseaux. Composés de différentes variétés d’arbustes ou petits arbres à floraison étalée tout au long de l’année, ces ensembles se plantent facilement dans les jardins .
                <br></br>
                <br></br>
                Cela permet d’offrir des habitats favorables à la reproduction, à l’hivernage. Cela fournit des ressources alimentaires : nectars, pollens, baies qui sont adaptés à la faune.
                <br></br>
                <br></br>
                Grâce aux floraisons et aux fructifications échelonnées dans le temps, c’est toute l’année que ces animaux peuvent passer à table.</p>
              <ButtonPrimary onClick={() => history.push('products?categories=Végétaux%20Biodiversités')}>Découvrir les produits</ButtonPrimary>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default PageHome;
