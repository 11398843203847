import {
  deleteProductInCart,
  editProductInCart,
  getProductInCart,
  getAnonymousCart,
  editProductInAnonymousCart,
  deleteProductInAnonymousCart,
  patchCart,
  patchCartAnonymousCart
} from "actions/cart";
import Prices from "components/Prices";
import { Product } from "models/Product";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useDispatch, useSelector } from "react-redux";
import { IMG_LINK } from "../../config/key";
import NcInputNumber from "components/NcInputNumber";
import PlaceIcon from "shared/NcImage/PlaceIcon";
import Input from "shared/Input/Input";
import { getOrderPreview, getOrderPreviewInAnonymousCart } from "actions/orders";
import { NotificationManager } from 'react-notifications';
import { t } from "i18next";
import { checkTokenValidity } from "actions/token";

const CartPage = () => {
  const history = useHistory();

  const [cart, setCart]: any = useState<Array<Product>>([]);
  const [moreQuantity, setMoreQuantity]: any = useState([])
  const [quantity, setQuantity]: any = useState(1);
  const dispatch = useDispatch();
  const { auth }: any = useSelector(state => state)
  const cartAnonymous: any = useSelector(state => state);
  const [loadingSpinner, setLoadingSpinner] = useState(true);
  const [discountCode, setDiscountCode]: any = useState();
  const [emptyAnonymousCart, setEmptyAnonymousCart]: any = useState(false);
  const [dataResponse, setDataResponse]: any = useState(false);

  const [quantities, setQuantities]: any = useState({});
  const newQuantities: any = { ...quantities };

  let oldScrollY = 0;

  const [direction, setDirection] = useState('');

  const controlDirection = () => {
    if (window.scrollY > oldScrollY) {
      setDirection("down");
    } else if (window.scrollY === 0 || (window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight) {
      setDirection("");
    }

    oldScrollY = window.scrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', controlDirection);
    return () => {
      window.removeEventListener('scroll', controlDirection);
    };
  }, []);

  useEffect(() => {
    if (cart && cart.item) {
      const initialQuantities: any = {};
      Object.keys(cart.item).forEach((key) => {
        initialQuantities[key] = cart.item[key]?.quantity || 1;
      });
      setQuantities(initialQuantities);
    }
  }, [cart]);

  useEffect(() => {

    if (auth?.token) {

      dispatch(getProductInCart()).then((res: any) => {
        setLoadingSpinner(false);
        setCart(res);
      })

    } else {

      if (cartAnonymous?.cart?.cartLength !== 0 && cartAnonymous?.cart?._id !== undefined) {
        dispatch(getAnonymousCart(cartAnonymous?.cart?._id)).then((res: any) => {
          setCart(res);
          setLoadingSpinner(false);

          if (res?.cartLength !== 0) {
            getOrderPreviewInAnonymousCart(cartAnonymous?.cart?._id).then((data: any) => {
              // setOrderPreview(data)
            })
          }
        })
      } else {
        setEmptyAnonymousCart(true)
        setLoadingSpinner(false)
      }

    }
  }, [dataResponse])

  const handleDeleteProductInCart = async (itemId: string) => {
    if (auth?.token) {
      await dispatch(deleteProductInCart(itemId)).then((res: any) => {
        dispatch(getProductInCart()).then((res: any) => {
          NotificationManager.success("Le produit a été supprimé de votre panier !", '');
          setLoadingSpinner(false);
          setCart(res);

          if (res?.cartLength !== 0) {
            getOrderPreview().then((data: any) => {
              // setOrderPreview(data)
            })
          } else {
            // setOrderPreview([])
          }
        })
      })
    } else {
      await dispatch(deleteProductInAnonymousCart(cartAnonymous?.cart?._id, itemId)).then((res: any) => {
        if (cartAnonymous?.cart?.cartLength !== 0 && cartAnonymous?.cart?._id !== undefined) {
          dispatch(getAnonymousCart(cartAnonymous?.cart?._id)).then((res: any) => {
            NotificationManager.success("Le produit a été supprimé de votre panier !", '');
            setCart(res);
            setLoadingSpinner(false);

            if (res?.cartLength !== 0) {
              getOrderPreviewInAnonymousCart(cartAnonymous?.cart?._id).then((data: any) => {
                // setOrderPreview(data)
              })
            } else {
              // setOrderPreview([])
            }
          })
        } else {
          setEmptyAnonymousCart(true)
          setLoadingSpinner(false)
        }
      })
    }
  }

  const handleAddDiscountCode = async () => {
    if (auth?.token) {
      const data = {
        discountCode: discountCode
      }

      await dispatch(patchCart(data)).then((res: any) => {
        setDataResponse(res);
      }).catch((err: any) => {
        NotificationManager.error(err?.response?.data?.error, '');
      })
    } else {

      const data = {
        discountCode: discountCode
      }

      await dispatch(patchCartAnonymousCart(cartAnonymous?.cart?._id, data)).then((res: any) => {
        setDataResponse(res);
      }).catch((err: any) => {
        NotificationManager.error(err?.response?.data?.error, '');
      })
    }
  }

  const handleEditProductInCart = async (data: any, itemId: any) => {

    if (data !== 'moreQuantity') {
      if (auth?.token) {
        await dispatch(editProductInCart(itemId, data)).then((res: any) => {
          NotificationManager.success("La quantité de votre produit a été modifier avec succès !", '');
          setDataResponse(res)
        })
      } else {
        await dispatch(editProductInAnonymousCart(cartAnonymous?.cart?._id, itemId, data)).then((res: any) => {
          NotificationManager.success("La quantité de votre produit a été modifier avec succès !", '');
          setDataResponse(res)
        })
      }
    }

    if (data === 'moreQuantity') {
      setMoreQuantity((state: any): any => [...state, itemId]);
    } else {
      setMoreQuantity((state: any) => state.filter((item: any) => item !== itemId))
    }


  }

  const renderProduct = () => {
    return (
      <>
        {cart?.item &&

          <>
            {Object.keys(cart?.item).map((item: any, index: number) => (

              <div
                key={index}
                className="relative flex py-8 sm:py-10 xl:py-4 first:pt-0 last:pb-0"
              >
                <div className="relative h-28 w-28 sm:w-40 flex-shrink-0 overflow-hidden flex items-center rounded-xl bg-slate-100">

                  <div key={index}>

                    {cart?.item[item]?.itemRef?.images?.image1Id
                      ?
                      <>
                        <img
                          className="h-full w-full"
                          src={IMG_LINK + cart?.item[item]?.itemRef?.images?.image1Id}
                          alt={item.name}
                        />
                      </>
                      :
                      <div className="flex justify-center items-center text-center">
                        <div className="w-1/2">
                          <PlaceIcon />
                        </div>
                      </div>
                    }
                  </div>

                </div>

                <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
                  <div>
                    <div className="flex">
                      <div className="flex-[1.5]">
                        <div className="flex justify-between">

                          <div>
                            <h3 className="text-base font-semibold">
                              <Link to={"/product-detail/" + cart?.item[item]?.itemRef.slug}>{cart?.item[item]?.name}</Link>
                            </h3>
                            <div className="mt-1.5 sm:mt-2.5 flex text-sm text-slate-600 dark:text-slate-300">
                              <div>
                                {cart?.item[item]?.itemRef?.properties &&
                                  <>
                                    {Object.keys(cart?.item[item]?.itemRef?.properties).map((propertie: any, index: number) => (
                                      <div key={index}>
                                        <div><span className="capitalize">{propertie}</span>: {cart?.item[item]?.itemRef?.properties[propertie]}</div>
                                      </div>
                                    ))}
                                  </>
                                }
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-row">
                            {cart?.item[item]?.rebatedPrice ? (
                              <div className="flex flex-row">
                                <Prices className="whitespace-nowrap" price={cart.item[item].rebatedPrice} />
                                {cart.item[item].price !== cart.item[item].rebatedPrice && (
                                  <Prices className="text-red-500 line-through whitespace-nowrap" contentClass="px-1 py-1 md:py-1.5 md:px-3 text-lg font-[500]" price={cart?.item[item]?.price} />
                                )}
                              </div>
                            ) : (
                              <div>
                                {cart?.item[item]?.priceWithDiscount
                                  ? (
                                    <>
                                      {cart.item[item]?.priceWithDiscount === cart.item[item].price ? (
                                        <Prices className="whitespace-nowrap" price={cart.item[item].price} />
                                      ) : (
                                        <div className="flex flex-row">
                                          <Prices className="whitespace-nowrap" price={cart.item[item].priceWithDiscount} />
                                          <Prices className="text-red-500 line-through whitespace-nowrap" contentClass="py-1 px-1 md:py-1.5 md:px-2.5 text-sm font-medium h-full" price={cart.item[item].price} />
                                        </div>
                                      )}
                                    </>
                                  )
                                  : <Prices className="whitespace-nowrap" price={cart?.item[item]?.price} />

                                }
                              </div>
                            )}

                          </div>
                        </div>

                        <div className="flex justify-between">

                          <div className="mt-3 flex w-full sm:hidden relative">

                            {moreQuantity &&
                              <>
                                {moreQuantity?.includes(cart?.item[item]?.itemId) ? (
                                  <div className="flex flex-row">
                                    <Input
                                      type={"number"}
                                      value={quantities[item]}
                                      onChange={(e) => setQuantities((prevQuantities: any) => ({ ...prevQuantities, [item]: e.target.value }))}
                                      placeholder={'10'}
                                      max={cart?.item[item]?.itemRef?.quantity}
                                      className="w-[40%]"
                                    />
                                    <a
                                      onClick={(e) => {
                                        const selectedQuantity = quantities[item];
                                        if (selectedQuantity > cart?.item[item]?.itemRef?.quantity) {
                                          const availableQuantity = cart?.item[item]?.itemRef?.quantity;
                                          NotificationManager.error(`Il ne reste que ${availableQuantity} produit${availableQuantity > 1 ? 's' : ''} disponible${availableQuantity > 1 ? 's' : ''}.`, '');
                                          return;
                                        }
                                        handleEditProductInCart(selectedQuantity, cart?.item[item]?.itemId);
                                      }}
                                      className="relative z-10 flex items-center ml-2 hover:underline font-medium text-primary-6000 hover:text-primary-500 text-sm cursor-pointer"
                                    >
                                      <span>Mettre à jour</span>
                                    </a>
                                  </div>
                                ) : (
                                  <select
                                    name="qty"
                                    value={quantities[item]}
                                    id={`select-${index}`}
                                    onChange={(e) => {
                                      const selectedValue = e.target.value;
                                      setQuantity(selectedValue);
                                      newQuantities[index] = selectedValue;
                                      setQuantities(newQuantities);
                                      handleEditProductInCart(selectedValue, cart?.item[item]?.itemId);
                                    }}
                                    className="form-select text-sm rounded-md py-1 border-slate-200 dark:border-slate-700 relative z-10 dark:bg-slate-800"
                                  >
                                    {Array.from({ length: Math.min(cart?.item[item]?.itemRef?.quantity, 10) }, (_, i) => i + 1).map(num => (
                                      <option key={num} value={num}>{num}</option>
                                    ))}
                                    {cart?.item[item]?.itemRef?.quantity > 9 && (
                                      <option value="moreQuantity">10+</option>
                                    )}
                                    {cart?.item[item]?.itemRef?.quantity > 10 && quantities[item] > 10 && (
                                      <option value={quantities[item]}>{quantities[item]}</option>
                                    )}
                                  </select>

                                )}
                              </>
                            }


                          </div>
                          <div>
                            <div className="flex mt-auto items-end justify-end text-sm">
                              <a
                                onClick={() => handleDeleteProductInCart(cart?.item[item]?.itemId)}
                                className="relative z-10 flex items-center mt-3 font-medium text-primary-6000 hover:text-primary-500 text-sm cursor-pointer"
                              >
                                <span className="hover:underline">{t("Remove")}</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="hidden sm:block text-center relative">
                        <NcInputNumber className="relative z-10" />
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            ))}
          </>
        }
      </>

    );
  };

  const handleDeleteDiscountCode = async () => {
    if (auth?.token) {

      const data = {
        discountCode: -1
      }

      await dispatch(patchCart(data)).then((res: any) => {
        setDataResponse(res)
      })
    } else {

      const data = {
        discountCode: -1
      }

      await dispatch(patchCartAnonymousCart(cartAnonymous?.cart?._id, data)).then((res: any) => {
        setDataResponse(res)
      })
    }
  }

  useEffect(() => {
    const token: any = localStorage.getItem('token');;
    if (history?.location?.search === "?forgot") {
      checkTokenValidity(token).then((res: any) => {
        if (res.data !== true) {
          history.push('/login')
        }
      })
    }

  }, [history])

  const handleCheckout = () => {
    if (cart?.cartLength === 0 || emptyAnonymousCart) {
      NotificationManager.error('Votre panier est vide !', '')
    } else {
      history.push("/checkout/information")
    }
  }

  return (
    console.log(cart.length),
    <div className="nc-CartPage relative">
      <Helmet>
        <title>Panier - fruitiers-anciens.fr</title>
      </Helmet>

      <main className="container py-16 lg:pb-28 lg:pt-20 ">
        <div className="mb-6 sm:mb-16">
          <h1 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
            {t('Shopping_Cart')}
          </h1>
        </div>

        <hr className="border-slate-200 dark:border-slate-700 my-10 xl:my-12" />

        {(cart?.cartLength === 0 || emptyAnonymousCart) &&
          <h1>Il n'y a aucun article dans votre panier.</h1>
        }

        <div className="flex flex-col lg:flex-row">
          <div className="w-full lg:w-[60%] xl:w-[55%]">

            {loadingSpinner &&
              <div className="flex justify-center">
                <svg className="w-12 h-12 animate-spin text-[#7c6555]" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 4.75V6.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  <path d="M17.1266 6.87347L16.0659 7.93413" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  <path d="M19.25 12L17.75 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  <path d="M17.1266 17.1265L16.0659 16.0659" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  <path d="M12 17.75V19.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  <path d="M7.9342 16.0659L6.87354 17.1265" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  <path d="M6.25 12L4.75 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  <path d="M7.9342 7.93413L6.87354 6.87347" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
              </div>
            }
            <div className="mb-6">
              <h3 className="text-lg font-semibold block lg:hidden xl:hidden">{t('Order_Summary')}</h3>
            </div>

            {renderProduct()}

          </div>

          <div className="border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 mt-10 mb-5 lg:my-0 lg:mx-10 xl:mx-16 2xl:mx-20 flex-shrink-0"></div>
          <div className="flex-1">
            <div className="sticky top-28">
              <h3 className="text-lg font-semibold hidden lg:block xl:block ">{t('Order_Summary')}</h3>
              <div className="mt-7 text-sm text-slate-500 dark:text-slate-400 divide-y divide-slate-200/70 dark:divide-slate-700/80">
                <div className="mt-8">
                  <h1 className="mb-2">Avez-vous un code promo?</h1>
                  <div className="flex flex-row mb-5">
                    <div className="flex w-full mr-4">
                      <Input className="w-full" onChange={(e: any) => setDiscountCode(e.target.value)} placeholder="Code promo" />
                    </div>
                    <ButtonPrimary onClick={() => handleAddDiscountCode()} className="h-1/4 w-1/4">
                      Ajouter
                    </ButtonPrimary>
                  </div>
                </div>

                <div>
                  <div className="flex justify-between pb-4">
                    <span className="mt-4">Sous-total ({cart?.cartLength} {cart?.cartLength === 1 ? "article" : "articles"})</span>
                    <span className="font-semibold text-slate-900 dark:text-slate-200 mt-4">
                      {cart?.length !== 0 ? cart?.discountedPriceWithoutTaxe ? cart?.discountedPriceWithoutTaxe?.toFixed(2) : cart?.cartPriceWithoutTaxe?.toFixed(2) : 0} €
                    </span>
                  </div>

                  <div className="flex justify-between pb-4">
                    <span className="mt-4">Dont TVA</span>
                    <span className="font-semibold text-slate-900 dark:text-slate-200 mt-4">
                      {cart?.length !== 0 ? cart?.taxePrice?.toFixed(2) : 0} €
                    </span>
                  </div>

                  {cart.discountCode &&
                    <div className="flex justify-between pb-4">
                      <span className="mt-4">Remise ({cart?.discountCode})</span>
                      <span className="font-semibold text-slate-900 dark:text-slate-200 mt-4">
                        - {cart?.discount?.amount}
                        {cart?.discount?.type === "percentage" ? "%" : "€"}
                      </span>
                      <span className="mt-4 cursor-pointer" onClick={() => handleDeleteDiscountCode()}>
                        <button className="w-5 h-5 flex items-center justify-center rounded-full bg-slate-200 dark:bg-slate-900 text-neutral-700 dark:text-slate-200 nc-shadow-lg ">
                          <span className="text-[12px]">x</span>
                        </button>
                      </span>
                    </div>
                  }

                  <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                    <div>
                      <span>{t("Order_Total")}</span>
                      <p className="text-slate-500 text-xs font-normal">Taxes incluses</p>
                    </div>
                    <>{cart?.length !== 0 ? cart?.discount?.amount ? (cart?.discountedPrice)?.toFixed(2) : cart?.cartPrice?.toFixed(2) : 0} € </>
                  </div>
                </div>

              </div>
              <ButtonPrimary onClick={() => handleCheckout()} className="mt-8 w-full">
                {t("Checkout")}
              </ButtonPrimary>
            </div>
          </div>
        </div>

      </main>
    </div>
  );
};

export default CartPage;

