import { Helmet } from "react-helmet";

const Cgv = () => {

    return (
        <div className="nc-PageHome relative overflow-hidden">
            <Helmet>
                <title>CGV | fruitiers-anciens.fr</title>
            </Helmet>


            <div className="flex justify-center py-10">
                <div className="max-w-5xl p-4">
                    <div className="text-center">
                        <h1 className="text-3xl font-bold mb-4">CONDITIONS GENERALES DE VENTE</h1>
                    </div>

                    <p className="mb-4 mt-14">Les présentes conditions générales de vente (ci-après les « Conditions Générales») s’appliquent à tout achat effectué par une personne physique (ci-après le « CLIENT») sur le site Internet https:// fruitiers-anciens.fr (ci-après le « SITE ») auprès de Pépinières SAINT-ROMAS SARL immatriculée au registre du commerce et des sociétés 394 467 443 R.C.S sous le numéro 394 467 443 R.C.S , ayant son siège social 570 Chemin du Moulin 82370 CORBARIEU, France Tél : 0563678826, email : saint-romas@orange.fr (ci-après le « VENDEUR »).</p>


                    <p className="mt-5">
                        <span className="font-semibold">ARTICLE 1 - DEFINITIONS</span> :
                        Les termes employés ci-après ont, dans les présentes Conditions Générales, la signification suivante :

                        <ul className="mt-4 ml-4">
                            <li>• « CLIENT » : désigne le cocontractant du VENDEUR, qui garantit avoir la qualité de consommateur telle que définie par le droit et la jurisprudence française. A ce titre, il est expressément prévu que le CLIENT agit en dehors de toute activité habituelle ou commerciale.</li>
                            <li>• « LIVRAISON » : désigne la première présentation des PRODUITS commandés par le CLIENT à l’adresse de livraison indiquée lors de la commande.</li>
                            <li>• « PRODUITS » : désigne l’ensemble des produits disponibles sur le SITE.</li>
                            <li>• « TERRITOIRE » : désigne la FRANCE Métropolitaine (hors DOM/TOM).</li>
                        </ul>
                    </p>

                    <p className="mt-5">
                        <span className="font-semibold">ARTICLE 2 - OBJET</span> :
                        Les présentes Conditions Générales régissent la vente par le VENDEUR à ses CLIENTS des PRODUITS.<br /><br />

                        Le CLIENT est clairement informé et reconnaît que le SITE s’adresse aux consommateurs et que les professionnels doivent contacter le service commercial du VENDEUR afin de bénéficier de conditions contractuelles distinctes
                    </p>

                    <p className="mt-5">
                        <span className="font-semibold">ARTICLE 3 - ACCEPTATION DES CONDITIONS GENERALES</span> :
                        Le CLIENT s’engage à lire attentivement les présentes Conditions Générales et les accepter, avant de procéder au paiement d’une commande de PRODUITS passée sur le SITE.<br /><br />

                        Les présentes Conditions Générales sont référencées en bas de chaque page du SITE au moyen d’un lien et doivent être consultées avant de passer la commande. Le CLIENT est invité à lire attentivement, télécharger, imprimer les Conditions Générales et à en conserver une copie.<br />
                        Le VENDEUR conseille au CLIENT de lire les Conditions Générales à chaque nouvelle commande, la dernière version desdites Conditions s’appliquant à toute nouvelle commande de PRODUITS.
                    </p>

                    <p className="mt-5">
                        <span className="font-semibold">ARTICLE 4 - ACHAT DE PRODUITS SUR LE SITE</span> :
                        Pour pouvoir acheter un PRODUIT, le CLIENT doit être âgé d’au moins 18 ans et disposer de la capacité légale ou, s’il est mineur, être en mesure de justifier de l’accord de ses représentants légaux.<br /> <br />

                        Le CLIENT sera invité à fournir des informations permettant de l’identifier en complétant le formulaire disponible sur le SITE. Le signe (*) indique les champs obligatoires qui doivent être remplis pour que la commande du CLIENT soit traitée par le VENDEUR. Le CLIENT peut vérifier sur le SITE le statut de sa commande. Le suivi des LIVRAISONS peut, le cas échéant, être effectué en utilisant les outils de suivi en ligne de certains transporteurs. Le CLIENT peut également contacter le service commercial du VENDEUR à tout moment par courrier électronique, à l’adresse boutique@pepinieres-naudet.com, afin d’obtenir des informations sur le statut de sa commande.
                    </p>

                    <p className="mt-5">
                        <span className="font-semibold">ARTICLE 5 - COMMANDES</span>:
                        {/* Content of Article 5 */}
                        <br /><br />
                        <span className="font-semibold ">Article 5.1 Caractéristique des produits</span>:
                        Le VENDEUR s’engage à présenter les caractéristiques essentielles des PRODUITS (sur les fiches d’information disponibles sur le SITE) et les informations obligatoires que le CLIENT doit recevoir en vertu du droit applicable.
                        <br />
                        Le CLIENT s’engage à lire attentivement ces informations avant de passer une commande sur le SITE.
                        <br />
                        Sauf indication expresse contraire sur le SITE, tous les PRODUITS vendus par le VENDEUR sont conformes à la législation européenne en vigueur et aux normes applicables en France.

                        <br /><br /><span className="font-semibold">Article 5.2 Procédure de commande</span>:
                        <ul className="mt-4 ml-4">
                            <li className="mt-4">
                                <span className="font-semibold">5.2.1 Sélection des PRODUITS et options d’achat</span>:
                                Le CLIENT devra sélectionner le(s) PRODUIT(s) de son choix en cliquant sur le(s) PRODUIT(s) concerné(s) et en choisissant les caractéristiques et les quantités souhaitées. Une fois le PRODUIT sélectionné, le PRODUIT est placé dans le panier du CLIENT. Ce dernier peut ensuite ajouter à son panier autant de PRODUITS qu’il le souhaite.
                            </li>
                            <li className="mt-4">
                                <span className="font-semibold">5.2.2 Commandes</span>:
                                Une fois les PRODUITS sélectionnés et placés dans son panier, le CLIENT doit cliquer sur le panier et vérifier que le contenu de sa commande est correct. Si le CLIENT ne l’a pas encore fait, il sera ensuite invité à s’identifier ou à s’inscrire.
                                <br />
                                Une fois que le CLIENT aura validé le contenu du panier et qu’il se sera identifié / inscrit, s’affichera à son attention un formulaire en ligne complété automatiquement et récapitulant le prix, les taxes applicables et, le cas échéant, les frais de livraison.
                                <br />
                                Le CLIENT est invité à vérifier le contenu de sa commande (y compris la quantité, les caractéristiques et les références des PRODUITS commandés, l’adresse de facturation, le moyen de paiement et le prix) avant de valider son contenu.
                                <br />
                                Le CLIENT peut alors procéder au paiement des PRODUITS en suivant les instructions figurant sur le SITE et fournir toutes les informations nécessaires à la facturation et à la LIVRAISON des PRODUITS. Concernant les PRODUITS pour lesquels des options sont disponibles, ces références spécifiques apparaissent lorsque les bonnes options ont été sélectionnées. Les commandes passées doivent comprendre toutes les informations nécessaires au bon traitement de la commande.
                                <br />
                                Le CLIENT doit également sélectionner le mode de livraison choisi.
                            </li>
                            <li className="mt-4">
                                <span className="font-semibold">5.2.3 Accusé de réception</span>:
                                Une fois que toutes les étapes décrites ci-dessus sont complétées, une page apparait sur le SITE afin d’accuser réception de la commande du CLIENT. Une copie de l’accusé de réception de la commande est automatiquement adressée au CLIENT par courrier électronique, à condition que l’adresse électronique communiquée par le biais du formulaire d’inscription soit correcte.
                                <br />
                                Le VENDEUR n’envoie aucune confirmation de commande par courrier postal ou par télécopie.
                            </li>
                            <li className="mt-4">
                                <span className="font-semibold">5.2.4 Facturation</span>:
                                Pendant la procédure de commande, le CLIENT devra saisir les informations nécessaires à la facturation (le signe (*) indiquera les champs obligatoires devant être remplis pour que la commande du CLIENT soit traitée par le VENDEUR).
                                <br />
                                Le CLIENT doit notamment indiquer clairement toutes les informations relatives à la LIVRAISON, en particulier l’adresse exacte de LIVRAISON, ainsi que tout éventuel code d’accès à l’adresse de LIVRAISON.
                                <br />
                                Le CLIENT doit également préciser le moyen de paiement choisi.
                            </li>
                        </ul>
                    </p>

                    <p className="mt-5">
                        <span className="font-semibold">ARTICLE 6 - DROIT DE RETRACTATION</span>:
                        Les modalités du droit de rétractation sont prévues dans la « politique de rétractation », politique disponible en Annexe 1 des présentes et accessible en bas de chaque page du SITE via un lien hypertexte.
                    </p>

                    <p className="mt-5">
                        <span className="font-semibold">ARTICLE 7 - PAIEMENT</span>:
                        <br /><br />
                        <div className="ml-4">
                            <span className="font-semibold">7.1. Moyens de paiement</span>:
                            Le CLIENT peut payer ses PRODUITS en ligne sur le SITE suivant les moyens proposés par le VENDEUR.
                            <br />
                            Le CLIENT garantit au VENDEUR qu’il détient toutes les autorisations requises pour utiliser le moyen de paiement choisi.
                            <br />
                            Le VENDEUR prendra toutes les mesures nécessaires pour garantir la sécurité et la confidentialité des données transmises en ligne dans le cadre du paiement en ligne sur le SITE.
                            <br />
                            Il est à ce titre précisé que toutes les informations relatives au paiement fournies sur le SITE sont transmises à la banque du SITE et ne sont pas traitées sur le SITE.
                            <br /><br />

                            <span className="font-semibold">7.2. Date de paiement</span>:
                            En cas de paiement unique par carte de crédit, le compte du CLIENT sera débité dès la commande de PRODUITS passée sur le SITE.
                            <br />
                            En cas de LIVRAISON partielle, le montant total sera débité du compte du CLIENT au plus tôt lorsque le premier colis sera expédié. Si le CLIENT décide d’annuler sa commande de PRODUITS indisponibles, le remboursement s’effectuera conformément au dernier paragraphe de l’article 5.5 des présentes Conditions Générales.
                            <br /><br />

                            <span className="font-semibold">7.3. Retard ou refus de paiement</span>:
                            Si la banque refuse de débiter une carte ou autre moyen de paiement, le CLIENT devra contacter le Service Client du VENDEUR afin de payer la commande par tout autre moyen de paiement valable.
                            <br />
                            Dans l’hypothèse où, pour quelle que raison que ce soit, opposition, refus ou autre, la transmission du flux d’argent dû par le CLIENT s’avèrerait impossible, la commande serait annulée et la vente automatiquement résiliée.
                        </div>
                    </p>

                    <p className="mt-5">
                        <span className="font-semibold">ARTICLE 8 - PREUVE ET ARCHIVAGE</span>:
                        Tout contrat conclu avec le CLIENT correspondant à une commande d’un montant supérieur à 120 euros TTC sera archivé par le VENDEUR pendant une durée de dix (10) ans conformément à l’article L. 213-1 du Code de la consommation.
                        <br />
                        Le VENDEUR accepte d’archiver ces informations afin d’assurer un suivi des transactions et de produire une copie du contrat à la demande du CLIENT.
                        <br />
                        En cas de litige, le VENDEUR aura la possibilité de prouver que son système de suivi électronique est fiable et qu’il garantit l’intégrité de la transaction.
                    </p>

                    <p className="mt-5">
                        <span className="font-semibold">ARTICLE 9 - TRANSFERT DE PROPRIETE</span>:
                        Le VENDEUR reste le propriétaire des PRODUITS livrés jusqu’à leur complet paiement par le CLIENT.
                        <br />
                        Les dispositions ci-dessus ne font pas obstacle au transfert au CLIENT, au moment de la réception par lui, ou par un tiers désigné par lui autre que le transporteur, des risques de perte ou de dommage des PRODUITS faisant l’objet de la réserve de propriété, ainsi que des risques de dommage qu’ils pourront entraîner.
                    </p>

                    <p className="mt-5">
                        <span className="font-semibold">ARTICLE 10 - LIVRAISON</span>:
                        Les modalités de LIVRAISON des PRODUITS sont prévues dans la « politique de livraison » visée en Annexe 2 des présentes et accessibles en bas de chaque page du SITE via un lien hypertexte.
                    </p>

                    <p className="mt-5">
                        <span className="font-semibold">ARTICLE 11 - EMBALLAGE</span>:
                        Les PRODUITS seront emballés conformément aux normes de transport en vigueur, afin de garantir un maximum de protection pour les PRODUITS pendant la LIVRAISON.
                        <br />
                        Les CLIENTS s’engagent à respecter les mêmes normes lorsqu’ils retournent des PRODUITS dans les conditions fixées en Annexe 1 – Politique de rétractation.
                    </p>

                    <div>
                        <p className="mt-5">
                            <span className="font-semibold">ARTICLE 12 - GARANTIES</span>:
                            <br /><br />
                            En dehors des garanties commerciales que le VENDEUR pourrait proposer pour certains PRODUITS, tout Client bénéficie de garanties « légales », pour l’ensemble des PRODUITS, qui sont détaillées ci-dessous, conformément à l’article L.111-1 du Code de la consommation
                            <br /><br />
                            <div className="ml-4">
                                <span className="font-semibold">Article 12.1. Garantie de conformité</span>:
                                Article L. 217-4 du Code de la consommation : «Le vendeur livre un bien conforme au contrat et répond des défauts de conformité existant lors de la délivrance. Il répond également des défauts de conformité résultant de l'emballage, des instructions de montage ou de l'installation lorsque celle-ci a été mise à sa charge par le contrat ou a été réalisée sous sa responsabilité».
                                <br />
                                Article L.217-5 du Code de la consommation : « Le bien est conforme au contrat : 1° S'il est propre à l'usage habituellement attendu d'un bien semblable et, le cas échéant : - s'il correspond à la description donnée par le vendeur et possède les qualités que celui-ci a présentées à l'acheteur sous forme d'échantillon ou de modèle ; - s'il présente les qualités qu'un acheteur peut légitimement attendre eu égard aux déclarations publiques faites par le vendeur, par le producteur ou par son représentant, notamment dans la publicité ou l'étiquetage ; 2° Ou s'il présente les caractéristiques définies d'un commun accord par les parties ou est propre à tout usage spécial recherché par l'acheteur, porté à la connaissance du vendeur et que ce dernier a accepté ».
                                <br />
                                Le VENDEUR est susceptible de répondre des défauts de conformité existants lors de la délivrance et des défauts de conformité résultant de l’emballage, des instructions de montage ou de l’installation lorsque celle-ci a été mise à sa charge ou a été réalisée sous sa responsabilité.
                                <br />
                                L’action résultant du défaut de conformité se prescrit par deux (2) ans à compter de la délivrance du PRODUIT (Article L.217-12 du Code de la consommation)
                                <br />
                                En cas de défaut de conformité, le CLIENT pourra demander le remplacement ou la réparation du PRODUIT, à son choix. Toutefois, si le coût du choix du CLIENT est manifestement disproportionné au regard de l’autre option envisageable, compte tenu de la valeur du PRODUIT ou de l’importance du défaut, le VENDEUR pourra procéder à un remboursement, sans suivre l’option choisie par le Client.
                                <br />
                                Dans l’hypothèse ou un remplacement ou une réparation serait impossible, le VENDEUR s’engage à restituer le prix du PRODUIT sous trente (30) jours à réception du PRODUIT retourné et en échange du renvoi du PRODUIT par le CLIENT à l’adresse suivante 570 Chemin du Moulin 82370 CORBARIEU.
                                <br />
                                Enfin, le CLIENT est dispensé de rapporter la preuve de l’existence du défaut de conformité du PRODUIT pendant les vingt-quatre (24) mois suivant la délivrance du PRODUIT excepté pour les biens d’occasion pour lesquels ce délai est fixé à six (6) mois. (Article L. 217-7 du Code de la consommation).
                                <br />
                                Il est précisé que la présente garantie légale de conformité s’applique indépendamment de la garantie commerciale consentie, le cas échéant, sur les PRODUITS.
                            </div>
                        </p>

                        <p className="mt-5 ml-4">
                            <span className="font-semibold">Article 12.2. Garantie des vices cachés</span>:
                            Le VENDEUR est tenu de la garantie à raison des vices cachés du PRODUIT vendu qui le rendent impropre à l’usage auquel on le destine, ou qui diminuent tellement cet usage que le CLIENT ne l’aurait pas acquis, ou n’en aurait donné qu’un moindre prix, s'il les avait connus. (Article 1641 du Code Civil)
                            <br />
                            Cette garantie permet au CLIENT qui peut prouver l’existence d’un vice caché de choisir entre le remboursement du prix du PRODUIT s’il est retourné et le remboursement d’une partie de son prix, si le PRODUIT n’est pas retourné.
                            <br />
                            Dans l’hypothèse où un remplacement ou une réparation serait impossible, le VENDEUR s’engage à restituer le prix du PRODUIT sous trente (30) jours à réception du PRODUIT retourné et en échange du renvoi du PRODUIT par le CLIENT à l’adresse suivante 570 Chemin du Moulin 82370 CORBARIEU. L’action résultant des vices rédhibitoires doit être intentée par le CLIENT dans un délai de deux (2) ans à compter de la découverte du vice. (Alinéa 1er de l’article 1648 du Code Civil)
                        </p>
                    </div>

                    <div>
                        <p className="mt-5">
                            <span className="font-semibold">ARTICLE 13 - RESPONSABILITE</span>:
                            La responsabilité du VENDEUR ne pourra en aucun cas être engagée en cas d’inexécution ou de mauvaise exécution des obligations contractuelles imputable au CLIENT, notamment lors de la saisie de sa commande.
                            <br />
                            Le VENDEUR ne pourra être tenu pour responsable, ou considéré comme ayant failli aux présentes, pour tout retard ou inexécution, lorsque la cause du retard ou de l’inexécution est liée à un cas de force majeure telle qu’elle est définie par la jurisprudence des cours et Tribunaux français.
                            <br />
                            Il est par ailleurs précisé que le VENDEUR ne contrôle pas les sites web qui sont directement ou indirectement liés au SITE. En conséquence, il exclut toute responsabilité au titre des informations qui y sont publiées. Les liens vers des sites web de tiers ne sont fournis qu’à titre indicatif et aucune garantie n’est fournie quant à leur contenu.
                        </p>
                    </div>

                    <p className="mt-5">
                        <span className="font-semibold">ARTICLE 14 - FORCE MAJEURE</span>:
                        La responsabilité du VENDEUR ne pourra pas être mise en œuvre si la non-exécution ou le retard dans l’exécution de l’une de ses obligations décrites dans les présentes CGV découle d’un cas de force majeure.
                        <br />
                        Il y a force majeure en matière contractuelle lorsqu’un évènement échappant au contrôle du débiteur, qui ne pouvait être raisonnablement prévu lors de la conclusion du contrat et dont les effets ne peuvent être évités par des mesures appropriées, empêche l’exécution de son obligation par le débiteur.
                        <br />
                        Si l’empêchement est temporaire, l’exécution de l’obligation est suspendue à moins que le retard qui en résulterait ne justifie la résolution du contrat. Si l’empêchement est définitif, le contrat est résolu de plein droit et les parties sont libérées de leurs obligations dans les conditions prévues aux articles 1351 et 1351-1 du Code civil.
                        <br />
                        A ce titre, la responsabilité du VENDEUR ne pourra pas être engagée notamment en cas d’attaque de pirates informatiques, d’indisponibilité de matériels, fournitures, pièces détachées, équipements personnels ou autres, d’interruption de réseaux de communications électroniques, ainsi qu’en cas de survenance de toute circonstance ou évènement extérieur à la volonté du VENDEUR intervenant postérieurement à la conclusion des CGV et en empêchant l’exécution dans des conditions normales.
                        <br /><br />
                        Il est précisé que, dans une telle situation, le CLIENT ne peut réclamer le versement d’aucune indemnité et ne peut intenter aucun recours à l’encontre du VENDEUR.

                        En cas de survenance d’un des évènements susvisés, le VENDEUR s’efforcera d’informer le CLIENT dès que possible.
                    </p>

                    <p className="mt-5">
                        <span className="font-semibold">ARTICLE 15 - DONNEES PERSONNELLES</span>:
                        Le VENDEUR collecte sur le SITE des données personnelles concernant ses CLIENTS, y compris par le biais de cookies. Les CLIENTS peuvent désactiver les cookies en suivant les instructions fournies par leur navigateur.
                        <br />
                        Les données collectées par le VENDEUR sont utilisées afin de traiter les commandes passées sur le SITE, gérer le compte du CLIENT, analyser les commandes et, si le CLIENT a expressément choisi cette option, lui envoyer des courriers de prospection commerciale, des newsletters, des offres promotionnelles et/ou des informations sur des ventes spéciales, sauf si le CLIENT ne souhaite plus recevoir de telles communications de la part du VENDEUR.
                        <br />
                        Les données du CLIENT sont conservées confidentiellement par le VENDEUR pour les besoins du contrat, de son exécution et dans le respect de la loi.
                        <br />
                        Les CLIENTS peuvent à tout moment se désinscrire en accédant à leur compte ou en cliquant sur le lien hypertexte prévu à cet effet en bas de chaque offre reçue par courrier électronique.
                        <br />
                        Les données peuvent être communiquées, en tout ou partie, aux prestataires de services du VENDEUR intervenant dans le processus de commande. A des fins commerciales, le VENDEUR peut transférer à ses partenaires commerciaux les noms et coordonnées de ses CLIENTS, à condition que ceux-ci aient expressément donné leur accord préalable lors de l’inscription sur le SITE.
                        <br />
                        Le VENDEUR demandera spécifiquement aux CLIENTS s’ils souhaitent que leurs données personnelles soient divulguées. Les CLIENTS pourront changer d’avis à tout moment en contactant le VENDEUR. Le VENDEUR peut également demander à ses CLIENTS s’ils souhaitent recevoir des sollicitations commerciales de ses partenaires.
                        <br />
                        Conformément à la loi n°78-17 du 6 janvier 1978 relative à l’informatique aux fichiers et aux libertés et au Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces données, et abrogeant la directive 95/46/CE (Règlement général sur la protection des données dit RGPD), le VENDEUR assure la mise en œuvre des droits des personnes concernées.
                        <br />
                        Il est rappelé que le CLIENT dont les données à caractère personnel sont traitées bénéficie des droits d’accès, de rectification, de mise à jour, de portabilité et d’effacement des informations qui le concernent, conformément aux dispositions des articles 39 et 40 de la loi Informatique et Libertés modifiée, et aux dispositions des articles 15, 16 et 17 du Règlement Général européen sur la Protection des Personnes (RGPD).
                        <br />
                        Conformément aux dispositions de l’article 38 de la loi Informatique & Libertés modifiée et aux dispositions de l’article 21 du RGPD, le CLIENT peut également, pour des motifs légitimes, s’opposer au traitement des données le concernant, sans motif et sans frais.
                        <br />
                        Le CLIENT peut exercer ces droits en adressant un courrier électronique à l’adresse : boutique@pepinieres-naudet.com ou en envoyant un courrier 570 Chemin du Moulin 82370 CORBARIEU.
                        <br />
                        Il est précisé que le CLIENT doit pouvoir justifier de son identité, soit en scannant une pièce d'identité, soit en adressant au VENDEUR une photocopie de sa pièce d'identité.
                    </p>

                    <p className="mt-5">
                        <span className="font-semibold">ARTICLE 16 - RECLAMATIONS</span>:
                        Le VENDEUR met à la disposition du CLIENT un « Service téléphonique Client » au numéro suivant : 05 63 67 88 26 (numéro non surtaxé). Toute réclamation écrite du CLIENT devra être transmise à l’adresse suivante : 570 Chemin du Moulin 82370 CORBARIEU.
                    </p>

                    <p className="mt-5">
                        <span className="font-semibold">ARTICLE 17 - PROPRIETE INTELLECTUELLE</span>:
                        Tous les éléments visuels et sonores du SITE, y compris la technologie sous-jacente utilisée, sont protégés par le droit d’auteur, le droit des marques et/ou des brevets.
                        <br />
                        Ces éléments sont la propriété exclusive du VENDEUR. Toute personne qui édite un site web et souhaite créer un lien hypertexte direct vers le SITE doit demander l’autorisation du VENDEUR par écrit.
                        <br />
                        Cette autorisation du VENDEUR ne sera en aucun cas accordée de manière définitive. Ce lien devra être supprimé à la demande du VENDEUR. Les liens hypertextes vers le SITE qui utilisent des techniques telles que le cadrage (framing) ou l’insertion par liens hypertextes (in-line linking) sont strictement interdits.
                        <br />
                        Toute représentation ou reproduction, totale ou partielle, du SITE et de son contenu, par quelque procédé que ce soit, sans l’autorisation préalable expresse du VENDEUR, est interdite et constituera une contrefaçon sanctionnée par les articles L.335-2 et suivants et les articles L.713-1 et suivants du Code de la Propriété Intellectuelle.
                        <br />
                        L’acceptation des présentes CGV vaut reconnaissance par le CLIENT des droits de propriété intellectuelle du VENDEUR et engagement à les respecter.
                    </p>

                    <p className="mt-5">
                        <span className="font-semibold">ARTICLE 18 - VALIDITE DES CONDITIONS GENERALES</span>:
                        Toute modification de la législation ou de la réglementation en vigueur, ou toute décision d’un tribunal compétent invalidant une ou plusieurs clauses des présentes Conditions Générales ne saurait affecter la validité des présentes Conditions Générales. Une telle modification ou décision n’autorise en aucun cas les CLIENTS à méconnaître les présentes Conditions Générales.
                        <br />
                        Toutes conditions non expressément traitées dans les présentes seront régies conformément à l’usage du secteur du commerce aux particuliers, pour les sociétés dont le siège social se situe en France.
                    </p>

                    <p className="mt-5">
                        <span className="font-semibold">Article 19 - MODIFICATION DES CONDITIONS GENERALES</span>:
                        Les présentes Conditions Générales s’appliquent à tous les achats effectués en ligne sur le SITE, tant que le SITE est disponible en ligne.
                        <br />
                        Les Conditions Générales sont datées de manière précise et pourront être modifiées et mises à jour par le VENDEUR à tout moment. Les Conditions Générales applicables sont celles en vigueur au moment de la commande.
                        <br />
                        Les modifications apportées aux Conditions Générales ne s’appliqueront pas aux PRODUITS déjà achetés.
                    </p>

                    <p className="mt-5">
                        <span className="font-semibold">ARTICLE 20 - COMPETENCE ET DROIT APPLICABLE</span>:
                        LES PRESENTES CONDITIONS GENERALES AINSI QUE LES RELATIONS ENTRE LE CLIENT ET LE VENDEUR SONT REGIES PAR LE DROIT FRANÇAIS.
                        <br />
                        EN CAS DE LITIGE, SEULS LES TRIBUNAUX FRANÇAIS SERONT COMPETENTS.
                        <br />
                        Toutefois, préalablement à tout recours au juge arbitral ou étatique, le Client est invité à contacter le service réclamation du VENDEUR.
                        <br />
                        Si aucun accord n’est trouvé ou si le CLIENT justifie avoir tenté, au préalable, de résoudre son litige directement auprès du VENDEUR par une réclamation écrite, il sera alors proposée une procédure de médiation facultative, menée dans un esprit de loyauté et de bonne foi en vue de parvenir à un accord amiable lors de la survenance de tout conflit relatif au présent contrat, y compris portant sur sa validité.
                        <br />
                        La partie souhaitant mettre en oeuvre le processus de médiation devra préalablement en informer l’autre partie par lettre recommandée avec accusé de réception en indiquant les éléments du conflit.
                        <br />
                        La médiation ne présentant pas un caractère obligatoire, le CLIENT ou le VENDEUR peut à tout moment se retirer du processus.
                        <br />
                        DANS L’HYPOTHESE OU LA MEDIATION ECHOUERAIT OU NE SERAIT PAS ENVISAGEE, LE LITIGE AYANT PU DONNER LIEU UNE MEDIATION SERA CONFIE A LA JURIDICTION COMPETENTE DESIGNEE CI-DESSUS.
                    </p>




                    <div className="mt-5">
                        <span className="font-semibold ">ANNEXE 1</span>
                        <h2 className="font-semibold text-lg mt-4">POLITIQUE DE RETRACTATION</h2>

                        <h3 className="font-semibold">Principe de rétractation</h3>
                        <p className="mt-4">
                            Le CLIENT dispose par principe du droit de se rétracter en renvoyant ou en restituant le PRODUIT au VENDEUR.
                            Pour cela, le PRODUIT devra être renvoyé ou restitué sans retard excessif, et au plus tard dans les quatorze (14) jours suivant la communication de sa décision de se rétracter, à moins que le VENDEUR ne propose de récupérer lui-même le PRODUIT.
                        </p>

                        <h3 className="font-semibold mt-4">Délai de rétractation</h3>
                        <p>
                            Conformément à l’article L.221-18 du Code de la consommation, le délai de rétractation expire quatorze (14) jours après le jour où le CLIENT, ou un tiers autre que le transporteur et désigné par le CLIENT, prend physiquement possession du PRODUIT.
                            Dans le cas où le CLIENT aurait commandé plusieurs PRODUITS via une seule commande donnant lieu à plusieurs LIVRAISONS (ou dans le cas d’une commande d’un seul PRODUIT livré en plusieurs lots), le délai de rétractation expirera quatorze (14) jours après le jour où le CLIENT, ou un tiers autre que le transporteur et désigné par le CLIENT, prend physiquement possession du dernier PRODUIT livré.
                            Si la commande du CLIENT porte sur plusieurs PRODUITS et si ces PRODUITS sont livrés séparément, le délai de rétractation expire quatorze (14) jours après le jour où le CLIENT, ou un tiers autre que le transporteur et désigné par le CLIENT, prend physiquement possession du dernier PRODUIT.
                        </p>

                        <h3 className="font-semibold mt-4">Notification du droit de rétractation</h3>
                        <p>
                            Pour exercer son droit de rétractation et conformément à l’article L.221-21 du Code de la consommation, le CLIENT doit notifier sa décision de se rétracter au moyen d’une déclaration dénuée d’ambiguïté (par exemple, lettre envoyée par la poste, télécopie ou courrier électronique) à : 570 Chemin du Moulin 82370 CORBARIEU ou saint-romas@orange.fr
                            Il peut également utiliser le formulaire ci-dessous:
                        </p>

                        <h4 className="font-semibold mt-4">FORMULAIRE DE RETRACTATION</h4>
                        <p>
                            A l'attention de : SAINT-ROMAS SARL<br />
                            Numéro de téléphone du VENDEUR : 0563678826<br />
                            Numéro de télécopieur du VENDEUR :<br />
                            Adresse de courrier électronique du VENDEUR*: saint-romas@orange.fr<br />
                            Je vous notifie par la présente ma rétractation du contrat portant sur la vente du PRODUIT ci-dessous:<br />
                            Référence du PRODUIT<br />
                            N° de la facture:<br />
                            N° du bon de commande:<br />
                            - Commandé le [________________]/reçu le [________________]<br />
                            - Moyen de paiement utilisé:<br />
                            - Nom du CLIENT et le cas échéant du bénéficiaire de la commande:<br />
                            - Adresse du CLIENT:<br />
                            - Adresse de livraison:<br />
                            - Signature du CLIENT (sauf cas de transmission par courriel)<br />
                            - Date<br />
                        </p>

                        <p>
                            Pour que le délai de rétractation soit respecté, le CLIENT doit transmettre sa communication relative à l’exercice du droit de rétractation avant l’expiration du délai de rétractation.
                        </p>

                        <h3 className="font-semibold mt-4">Effets de la rétractation</h3>
                        <p>
                            En cas de rétractation de la part du CLIENT, le VENDEUR s’engage à rembourser la totalité des sommes versées, y compris les frais de livraison (à l’exception des frais supplémentaires découlant, le cas échéant, du choix par le CLIENT d’un mode de livraison autre que le mode de livraison standard proposé par le VENDEUR) sans retard excessif et, en tout état de cause, au plus tard quatorze (14) jours à compter du jour où le VENDEUR réceptionnera le PRODUIT retourné (Article L.221-24 du Code de la consommation).
                            Le VENDEUR procédera au remboursement en utilisant le même moyen de paiement que celui que le CLIENT aura utilisé pour la transaction initiale, sauf si le CLIENT convient expressément d’un moyen différent, en tout état de cause, ce remboursement n’occasionnera pas de frais pour le CLIENT.
                            Le VENDEUR n’est pas tenu de rembourser les frais supplémentaires si le CLIENT a expressément choisi un mode de livraison plus coûteux que le mode de livraison standard proposé par le VENDEUR.
                            Le VENDEUR peut différer le remboursement jusqu’à réception du bien ou jusqu’à ce que le CLIENT ait fourni une preuve d’expédition du bien, la date retenue étant celle du premier de ces faits.
                        </p>

                        <h3 className="font-semibold mt-4">Modalités de retour</h3>
                        <p>
                            Le CLIENT devra, sans retard excessif et, en tout état de cause, au plus tard quatorze (14) jours après communication de sa décision de se rétracter du présent contrat, renvoyer le bien, à : 570 Chemin du Moulin 82370 CORBARIEU.
                            Ce délai est réputé respecté si le CLIENT renvoie le bien avant l’expiration du délai de quatorze (14) jours.
                        </p>

                        <h3 className="font-semibold mt-4">Frais de retour</h3>
                        <p>
                            Le CLIENT devra prendre en charge les frais directs de renvoi du bien.
                            Dans le cas où le poids du PRODUIT empêcherait le CLIENT de retourner ce PRODUIT par la Poste, le CLIENT devra prendre en charge les frais directs de renvoi du bien.
                        </p>
                    </div>


                </div>
            </div>


        </div>
    );
}

export default Cgv;
